import { ExtendedContent, ExtendedRoot, Heading } from 'mdast';

export const remarkFlattenImage = () => (tree: ExtendedRoot) => {
  const result = tree;
  result.children = result.children.map((content) => {
    if (content.type === 'paragraph') {
      const children = content.children as ExtendedContent[];
      if (children.length === 1 && children[0].type === 'image') { return children[0]; }
    }
    return content;
  });
  return result;
};

export const remarkChangelog = () => (tree: ExtendedRoot) => {
  const result = tree;
  result.children.forEach((content) => {
    let node = content;
    if (node.type === 'paragraph' && node.children[0].type === 'text') {
      const text = node.children[0].value;
      if (text === ('Fixes:') || text === 'Improvements:') {
        node = node as unknown as Heading;
        node.depth = 2;
        node.type = 'heading';
        node.children[0].value = text.replace('Fixes:', '🛠 Fixes')
          .replace('Improvements:', '💊 Improvements');
      }
    }
    return node;
  });
  return result;
};
