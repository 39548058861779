import changelog from '@Docs/Changelog.md';

export type Release = {
  version: string;
  minimumSystemName: string;
};

export const fetchCurrentRelease = async (): Promise<Release> => {
  const response = await fetch(changelog);
  const changelogContent = await response.text();
  const release = changelogContent.match(/^# (\d+\.\d+\.\d+), \d{4}\.\d{2}\.\d{2}, (?:macOS|OS X) (\d+(?:\.\d+)?) - (?:macOS|OS X) \d+(?:\.\d+)?$/m);
  if (!release) throw new Error('Cannot get the latest release version from changelog file.');
  if (!systemVersionNames[release[2]]) throw new Error(`Unknown macOS version ${release[2]}.`);
  return {
    version: release[1],
    minimumSystemName: systemVersionNames[release[2]],
  };
};

const systemVersionNames: Record<string, string> = {
  '10.12': 'macOS 10.12 Sierra',
  '10.13': 'macOS 10.13 High Sierra',
  '10.14': 'macOS 10.14 Mojave',
  '10.15': 'macOS 10.15 Catalina',
  '11': 'macOS 11 Big Sur',
  '12': 'macOS 12 Monterey',
  '13': 'macOS 13 Ventura',
  '14': 'macOS 14 Sonoma',
  '15': 'macOS 15 Sequoia',
};
