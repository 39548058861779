export type CurrencyDictionary = {
  [key: string]: Currency
};

export type Currency = {
  prefix?: string;
  suffix?: string;
};

export const currencyDictionary: CurrencyDictionary = {
  USD: {
    prefix: 'USD',
  },
  GBP: {
    prefix: 'GBP',
  },
  EUR: {
    suffix: 'EUR',
  },
  CAD: {
    suffix: 'CAD',
  },
  NZD: {
    suffix: 'NZD',
  },
  RUB: {
    suffix: 'RUB',
  },
  UAH: {
    suffix: 'UAH',
  },
  YEN: {
    prefix: 'YEN',
  },
};
