import { queryClient } from '@API/QueryClient';
import { DocumentationContextProvider, EFooterTheme, ThemeContextProvider, useThemeContext } from '@Shared/Context';
import { ContentRoot, LayoutRoot } from '@Shared/Layout';
import { Footer } from '@Shared/Layout/Footer';
import { Header } from '@Shared/Layout/Header';
import { GlobalLayoutStyle } from '@Shared/Style';
import React from 'react';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from './Router';

export const App: React.FC = () => (
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeContextProvider>
        <DocumentationContextProvider>
          <BrowserRouter>
            <HelmetProvider>
              <Layout />
            </HelmetProvider>
          </BrowserRouter>
        </DocumentationContextProvider>
      </ThemeContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

const Layout: React.FC = () => {
  const { theme } = useThemeContext();
  const overflow = theme.footerTheme == EFooterTheme.Light ? 'visible' : 'hidden';

  return (
    <>
      <GlobalLayoutStyle />
      <LayoutRoot overflow={overflow}>
        <Header />
        <ContentRoot>
          <Router />
        </ContentRoot>
        <Footer />
      </LayoutRoot>
    </>
  );
};
