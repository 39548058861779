import { Description, ExternalSilentLink, KeyShortcut, Quote } from '@Shared/Style';
import { Image, List } from '@Shared/Component';
import React from 'react';

type FAQItem = {
  title: string;
  icon: React.ReactChild;
  renderDescription: () => React.ReactChild;
  isOpen?: boolean;
};

type FAQSection = {
  title: string;
  items: FAQItem[];
};

export const faqSections: FAQSection[] = [
  {
    title: 'Getting Started',
    items: [
      {
        title: 'How to launch and quit Gifox?',
        icon: '👵',
        renderDescription: () => (
          <>
            <Description>
              Gifox is a status bar app — after launch it becomes visible on the right side of status bar at the top of screen among Wi-Fi, Date & Time, Battery Status and other icons. However, if you have small screen with too many status bar apps running or using another app with a very large menu, like Photoshop or
              Excel, it might get automatically hidden by the system. If this happens you can shut down other status bar apps that are not in use and switch to Finder or App Store — they have very compact menus giving plenty of freedom for status bar apps.
            </Description>
            <Image src="/image/faq/launch-and-quit.gif" />
          </>
        ),
      },
      {
        title: 'How to start and stop recording using shortcuts?',
        icon: '😎',
        renderDescription: () => (
          <>
            <Description>
              Gifox provides configurable shortcuts to control entire selection and recording workflow:
              <br />
              <br />
              <List>
                <li>
                  <KeyShortcut>⌘ Cmd</KeyShortcut> + <KeyShortcut>⇧ Shift</KeyShortcut> + <KeyShortcut>5</KeyShortcut> — starts area mode selection session{' '}
                </li>
                <li>
                  <KeyShortcut>⌘ Cmd</KeyShortcut> + <KeyShortcut>⇧ Shift</KeyShortcut> + <KeyShortcut>6</KeyShortcut> — start window mode selection session
                </li>
                <li>
                  <KeyShortcut>← ↑ → ↓ Arrows</KeyShortcut> — move current area selection, combine with <KeyShortcut>⇧ Shift</KeyShortcut> for ×10 effect
                </li>
                <li>
                  <KeyShortcut>⌘ Cmd</KeyShortcut> + <KeyShortcut>← ↑ → ↓ Arrows</KeyShortcut> — resize current area selection, combine with <KeyShortcut>⇧ Shift</KeyShortcut> for ×10 effect
                </li>
                <li>
                  <KeyShortcut>⌘ Cmd</KeyShortcut> + <KeyShortcut>⌥ Option</KeyShortcut> + <KeyShortcut>← ↑ → ↓ Arrows</KeyShortcut> — resize current area selection around center, combine with <KeyShortcut>⇧ Shift</KeyShortcut> for ×10 effect
                </li>
                <li>
                  <KeyShortcut>⌘ Cmd</KeyShortcut> + <KeyShortcut>D</KeyShortcut> — clear current selection
                </li>
                <li>
                  <KeyShortcut>⌘ Cmd</KeyShortcut> + <KeyShortcut>A</KeyShortcut> — select entire screen
                </li>
                <li>
                  <KeyShortcut>⎋ Esc</KeyShortcut> — cancel current selection session
                </li>
                <li>
                  <KeyShortcut>␣ Space</KeyShortcut> — start recording current selection
                </li>
                <li>
                  <KeyShortcut>⌘ Cmd</KeyShortcut> + <KeyShortcut>Esc</KeyShortcut> — stop recording
                </li>
              </List>
            </Description>
            <Image src="/image/faq/customize-shortcuts.gif" />
          </>
        ),
      },
      {
        title: 'How to get support or leave feedback?',
        icon: '🤨',
        renderDescription: () => (
          <>
            <Description>Use contact widget in the right bottom corner on this page or builtin feedback form — click Gifox icon in status bar to open assistant popover and click on message button.</Description>
            <Image src="/image/faq/leave-feedback.gif" />
          </>
        ),
      },
    ],
  },
  {
    title: 'Settings',
    items: [
      {
        title: 'What configuration gives best results?',
        icon: '🤔',
        renderDescription: () => (
          <>
            <Description>
              Good questions! In GIF world best result is a balance between image quality, file size and compression time. Knowing <ExternalSilentLink href="https://en.wikipedia.org/wiki/GIF">how GIF works</ExternalSilentLink> will significantly help with finding that balance, in a nutshell:
              <br />
              <br />
              <List>
                <li>Animated GIF is a series of compressed image frames.</li>
                <li>Each frame is limited to 256 colours.</li>
                <li>Those colours are stored in palettes.</li>
                <li>Palette can be global for all or unique for each frame.</li>
                <li>During compression original colours are replaced with ones in a palette.</li>
                <li>
                  <ExternalSilentLink href="https://en.wikipedia.org/wiki/Dither">Dithering</ExternalSilentLink> can be used to apply colours more evenly and improve final image.
                </li>
              </List>
              <br />
              So, with this in mind, here are some facts:
              <br />
              <br />
              <List>
                <li>More colours give better looking image closer to uncompressed, but increase file size and compression time.</li>
                <li>Global palette for all frames gives more consistent colour across entire GIF and smaller file size, but significantly increases compression time.</li>
                <li>Palette per each frame may give richer colours across entire GIF, but same colour may appear differently in two frames.</li>
                <li>Palette per each frame also results in faster compression, but significantly increases file size.</li>
                <li>Dithering significantly improves colour appearance in images with gradual colours, but increases compression time.</li>
              </List>
            </Description>
          </>
        ),
      },
      {
        title: 'How to change default keyboard shortcuts?',
        icon: '🤓',
        renderDescription: () => (
          <>
            <Quote>
              Global shortcuts, or hotkeys, consist of a standard key and modifier, like <KeyShortcut>⌘ Cmd</KeyShortcut>, <KeyShortcut>⌥ Option</KeyShortcut>, <KeyShortcut>^ Control</KeyShortcut>, <KeyShortcut>⇧ Shift</KeyShortcut> or <KeyShortcut>⇪ CapsLock</KeyShortcut> — they cannot simply be a single key, like
              {' '}<KeyShortcut>⎋ Esc</KeyShortcut> or <KeyShortcut>␣ Space</KeyShortcut>.
            </Quote>
            <Description>
              Click Gifox icon in status bar to open assistant popover and click on preference button. Navigate to shortcuts tab and select shortcut you’d like to change. Press <KeyShortcut>⌫ Delete</KeyShortcut> to remove it or new hotkey combination to set as the new shortcut.
            </Description>
            <Image src="/image/faq/leave-feedback.gif" />
          </>
        ),
      },
    ],
  },
  {
    title: 'Sharing',
    items: [
      {
        title: 'What services does Gifox integrate with?',
        icon: '🤖',
        renderDescription: () => (
          <>
            <Description>Gifox provides native integration with Dropbox, Google Drive and Imgur, and supports direct drag-and-drop into Twitter, Facebook, GitHub and other services. You can also copy your recordings directly to pasteboard, but please be aware that it might not work as expected.</Description>
            <Image src="/image/faq/drag-and-drop.gif" />
          </>
        ),
      },
      {
        title: 'How to add new integration?',
        icon: '🙃',
        renderDescription: () => (
          <>
            <Description>Click Gifox icon in status bar to open assistant popover and click on preference button. Navigate to sharing tab, chose service to integrate with and authenticate it. Once authenticated you can specify upload folder for file storage services, like Dropbox and Google Drive.</Description>
            <Image src="/image/faq/add-integration.gif" />
          </>
        ),
      },
      {
        title: 'How to copy or drag GIFs quickly?',
        icon: '🤓',
        renderDescription: () => (
          <>
            <Description>
              You can drag-and-drop recorded GIFs into all applications and web services supporting it, including Main, Messages, Skype, Twitter, Facebook, GitHub and other services — simply click Gifox icon in status bar to open assistant popover and drag recording or press clipboard button. Please be aware that
              copying might not work as expected and depends mostly on the application where GIF is being pasted into.
            </Description>
            <Image src="/image/faq/drag-and-drop.gif" />
          </>
        ),
      },
    ],
  },
  {
    title: 'Troubleshooting',
    items: [
      {
        title: 'Where is my license and how to recover if it is lost?',
        icon: '🙄',
        renderDescription: () => (
          <>
            <Description>
              If you purchased Gifox from Mac App Store prior version 1.5.1 you don’t need a license, after that Gifox comes with a Pro in-app purchase, which can be purchased / restored from licensing dialogue. When restoring previous purchases you’ll need to be signed in with the same Apple ID, see{' '}
              <ExternalSilentLink href="https://support.apple.com/en-us/HT204530">official Apple guide</ExternalSilentLink> for details and troubleshooting.
              <br />
              <br />
              When purchased directly from our website you should receive an email confirming the transaction and containing the licence. Sometimes it may take a little longer, but at most 5-10 minutes. If you didn’t receive an email or lost your license the fastest way to retrieve it is from{' '}
              <ExternalSilentLink href="https://my.paddle.com/">my.paddle.com</ExternalSilentLink> — you might need to register first or use restore password form.
            </Description>
          </>
        ),
      },
      {
        title: 'Why my license is not activating or stops working?',
        icon: '😐',
        renderDescription: () => (
          <>
            <Description>
              First, make sure you’re using correct Gifox distribution. If you purchased it in Mac App Store you should download it from there or directly from our website if purchased it directly — both versions use different licensing and not compatible with each other.
              <br />
              <br />
              If Gifox Pro in-app purchase is not working please make sure that it has successfully completed by <ExternalSilentLink href="https://support.apple.com/en-us/HT204088">confirming it’s present in purchase</ExternalSilentLink> history. If so, simply restore purchases from licensing dialogue, otherwise, try purchasing it
              again.
              <br />
              <br />
              If you’re having troubles with making a purchase or restoring, refer to <ExternalSilentLink href="https://support.apple.com/en-us/HT204530">official Apple guide</ExternalSilentLink> for details and troubleshooting. TL; DR: restart your computer and try again!
              <br />
              <br />
              If direct-download license is not activating, make sure you’re using correct email and not exceeding three activations per license, otherwise, deactivate it on other machines first.
              <br />
              <br />
              If none of the above helps or after successful activation your copy of Gifox stops working or asks for activation again please quit the app, find it in Finder, compress and email it to <ExternalSilentLink href="team@gifox.app">team@gifox.app</ExternalSilentLink> — we’ll look into what’s wrong and make sure it doesn’t
              happen in future releases. After that delete Gifox, download the latest version from App Store or directly from our website depending on your licence and try activating it again.
            </Description>
          </>
        ),
      },
      {
        title: 'Why created GIF play too fast or too slow?',
        icon: '😡',
        renderDescription: () => (
          <>
            <Description>
              GIF playback speed depends on playback fps (frames per second) configuration and the app itself that’s playing it back. Playback fps can be changed in general preferences, however, different apps use different playback mechanisms and may ignore very low/high fps values or load entire GIF into memory
              first, which will result in delayed playback that will be considerably longer for bigger files or older hardware.
              <br />
              <br />
              Standard macOS apps, including Finder, QuickView and Safari, are known to have these issues. We suggest trying Google Chrome to verify doubtful GIFs — it uses highly efficient FFmpeg decoder and outperforms standard macOS apps by far when comes to this particular job.
            </Description>
          </>
        ),
      },
      {
        title: 'Why GIF says that it’s damaged?',
        icon: '🤬',
        renderDescription: () => (
          <>
            <Description>
              Don’t panic! This is a consistent behaviour for large GIFs opened in standard macOS apps prior 10.13 High Sierra, including Finder, Safari and QuickView, and occurs when system doesn’t have enough memory to preload the entire file. Most likely there’s nothing wrong with your GIF and to confirm you can
              open it with Google Chrome — it uses more efficient playback mechanism and doesn’t have these issues.
            </Description>
          </>
        ),
      },
    ],
  },
];
