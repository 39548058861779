import React from 'react';
import styled from 'styled-components';
import { Colors, mediaMobile, Sizes, Spacings } from '../Style';
import { Image } from './Image';

type SectionDirection = 'forward' | 'reverse';

type FeatureSectionProps = {
  imageSrc?: string;
  direction?: SectionDirection;
  transparent?: boolean;
};

export const FeatureSection: React.FC<React.PropsWithChildren<FeatureSectionProps>> = ({ children, imageSrc, direction = 'forward', transparent }) => (
  <Section transparent={transparent}>
    <SectionContent direction={direction}>
      <SectionChildren>
        {children}
      </SectionChildren>
      <Image src={imageSrc} hasDensityVariants />
    </SectionContent>
  </Section>
);

const Section = styled.section<{ transparent?: boolean }>`
  background-color: ${({ transparent }) => transparent ? 'transparent' : Colors.accentLight};
  min-height: 36.25rem;
  padding-bottom: 2.125rem;

  &:last-of-type {
    padding-bottom: 10rem;
    margin-bottom: 0;
  }
`;

const SectionContent = styled.div<{ direction: SectionDirection }>`
  display: flex;
  max-width: ${Sizes.containerWidth};
  padding: 0 ${Spacings.spacingHorizontalMedium};
  margin: 0 auto;
  flex-flow: row nowrap;
  flex-direction: ${({ direction }) => (direction === 'forward' ? 'row' : 'row-reverse')};
  justify-content: space-between;
  align-items: center;
  min-height: 36.25rem;

  img {
    max-width: 57.8%;
    width: 42.5rem;
    margin-top: 0.35rem;
    ${({ direction }) => (direction === 'forward' ? 'margin-left: -2%;' : '')}
  }

  ${mediaMobile} {
    flex-flow: row wrap;

    img, video {
      max-width: 90%;
      margin: 0 auto;
    }
  }
`;

const SectionChildren = styled.div`
  box-sizing: border-box;
  position: relative;
  max-width: 32.2%;
  width: 23.75rem;
  z-index: 2;

  ${mediaMobile} {
    max-width: 100%;
    margin: 0 auto;
    padding: ${Spacings.spacingVerticalMedium} ${Spacings.spacingHorizontalMedium};
  }
`;
