import { useCallback } from 'react';

type CalculatePriceParams = {
  price: number | undefined;
  count?: number;
  priceCalculation?: (price: number, count: number) => number;
};

export const usePriceFormatter = () => {
  const formatPrice = useCallback(
    ({ price, count }: CalculatePriceParams): { price: string; totalPrice: string } => {
      if (!price) {
        return {
          price: "",
          totalPrice: "",
        };
      }
      let result = price;

      if (count != undefined) {
        result = price * count;
      }

      return {
        price: price.toString(),
        totalPrice: `${result.toFixed(2)}`,
      };
    }, [],
  );

  return { formatPrice };
};
