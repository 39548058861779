import { usePaddlePriceQuery } from '@API/Paddle';
import { ScrollHistory } from '@App/ScrollHistory';
import { PADDLE_PRODUCT_ID } from '@Constant';
import { HomePage } from '@Page/HomePage';
import { IntegrationCallbackPage } from '@Page/IntegrationCallbackPage';
import { PricingPage } from '@Page/PricingPage';
import { DownloadPage } from '@Page/DownloadPage';
import { CheckoutPage } from '@Page/CheckoutPage';
import { ChangelogPage } from '@Page/ChangelogPage';
import { PrivacyPage } from '@Page/PrivacyPage';
import { DocumentationRouter } from '@Page/Documentation/DocumentationRouter';
import { NotFoundPage } from '@Page/NotFoundPage';
import { TermsPage } from '@Page/TermsPage';
import { defaultTheme, lightFooterTheme, useThemeContext, transparentFooterTheme } from '@Shared/Context';
import { usePrevious } from '@Shared/Hook/Previous';
import React, { useEffect } from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';

export enum ERoute {
  Home = '/',
  Pricing = '/pricing',
  Buy = '/buy',
  Download = '/download',
  FAQ = '/docs/FAQ',
  Privacy = '/privacy',
  Terms = '/terms',
  Checkout = '/checkout',
  Changelog = '/changelog',
  Docs = '/docs',
  Integration = '/integration/oauth',
  NotFound = '/404',
}

export const Router: React.FC = () => {
  const location = useLocation();
  const { setTheme } = useThemeContext();
  const previousPathname = usePrevious(location.pathname);

  // Prefetch prices.
  usePaddlePriceQuery({ productId: PADDLE_PRODUCT_ID });

  useEffect(() => {
    if (previousPathname !== location.pathname) {
      const pathComponents = location.pathname.split('/');
      switch (`/${pathComponents[1]}`) {
        case ERoute.Home:
          return setTheme(defaultTheme);
        case ERoute.Privacy:
        case ERoute.Terms:
        case ERoute.Docs:
        case ERoute.Changelog:
          return setTheme(lightFooterTheme);
        default:
          return setTheme(transparentFooterTheme);
      }
    }
  }, [previousPathname, location, setTheme]);

  return (
    <>
      <ScrollHistory />
      <Switch>
        <Route exact path={ERoute.Home} component={HomePage} />
        <Route exact path={`${ERoute.Buy}/:hash?`} component={PricingPage} />
        <Redirect exact from={`${ERoute.Pricing}/:hash`} to={`${ERoute.Buy}/:hash`} />
        <Redirect exact from={ERoute.Pricing} to={ERoute.Buy} />
        <Route exact path={ERoute.Download} component={DownloadPage} />
        <Route exact path={ERoute.Privacy} component={PrivacyPage} />
        <Route exact path={ERoute.Terms} component={TermsPage} />
        <Route exact path={ERoute.Checkout} component={CheckoutPage} />
        <Route exact path={ERoute.Changelog} component={ChangelogPage} />
        <Redirect from="/faq" to={ERoute.FAQ} />
        <Route exact path={`${ERoute.Docs}/:key`} component={DocumentationRouter} />
        <Route exact path={ERoute.Docs} component={DocumentationRouter} />
        <Route exact path={`${ERoute.Integration}/:service`} component={IntegrationCallbackPage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </>
  );
};
