import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { ERoute } from '@App/Router';

export const useNavigate = () => {
  const history = useHistory();

  return useCallback(
    <S>(to: ERoute, state?: S) => () => {
      history.push(to, state);
    },
    [history],
  );
};
