import { ERoute } from '@App/Router';
import { reachGoal } from '@Helper';
import { Arch } from '@Shared/Component';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { PageContainer } from '@Shared/Layout';
import { Heading2, Description, ExternalLink } from '@Shared/Style';
import { useHistory, useLocation } from 'react-router-dom';
import { URL_DOWNLOAD_LATEST } from '@Constant';
import { Helmet } from 'react-helmet-async';

export const DownloadPage: React.FC = () => {
  const [download, setDownload] = useState(false);
  const history = useHistory();
  const location = useLocation<{ downloadUrl: string | undefined }>();
  const downloadLink = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    const downloadTimeout = setTimeout(() => {
      downloadLink.current?.click();
      reachGoal('hero_download');
    }, 1000);

    const downloadStartedTimeout = setTimeout(() => {
      setDownload(true);
    }, 5000);

    const redirectTimeout = setTimeout(() => {
      history.push(ERoute.Home);
    }, 9000);

    return () => {
      clearTimeout(downloadTimeout);
      clearTimeout(downloadStartedTimeout);
      clearTimeout(redirectTimeout);
    };
  }, [history]);

  useEffect(() => {
    if (location.state?.downloadUrl && downloadLink.current) {
      downloadLink.current.href = location.state.downloadUrl;
    }
  }, [location.state, downloadLink]);

  return (
    <>
      <Helmet>
        <title>Download – Gifox 🦊</title>
      </Helmet>
      <DownloadBlock>
        <Arch top="16rem" />
        <PageContainer textAlign="center">
          <DownloadLogo download={download} />
          {!download ? (
            <>
              <Heading2>Downloading Gifox…</Heading2>
              <Description>
                If the download doesn&apos;t start automatically in a few seconds, click{' '}
                <ExternalLink href={URL_DOWNLOAD_LATEST} target="_self" ref={downloadLink}>
                  here
                </ExternalLink>
                .
              </Description>
            </>
          ) : (
            <>
              <Heading2>Thanks!</Heading2>
              <Description>Redirecting you to homepage…</Description>
            </>
          )}
        </PageContainer>
      </DownloadBlock>
    </>
  );
};

const DownloadLogo = styled.div<{ download?: boolean }>`
  position: relative;
  width: 26.625rem;
  height: 24.5rem;
  margin: 0 auto;
  transition: clip-path 1s ease-in-out;
  clip-path: polygon(0 0, 0 12.375rem, 0 24.5rem, 13.25rem 24.5rem, 13.4375rem 24.5rem, 26.625rem 24.5rem, 26.625rem 12.375rem, 26.625rem 0, 17.5rem 0, 13.3125rem 0, 9.1875rem 0);
  ${({ download }) => (download ? 'clip-path: polygon(9.128rem 7.18rem, 7.5625rem 12.4375rem, 7.5625rem 12.5625rem, 13.3125rem 17.375rem, 13.3125rem 17.375rem, 19.0625rem 12.625rem, 19.0625rem 12.5rem, 17.51rem 7.2rem, 17.37rem 7.2rem, 13.3125rem 10.1875rem, 9.228rem 7.18rem);' : '')}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('/image/logo-backplate.svg') no-repeat 0 0;
    background-size: 100%;
    animation: 60s rotate;
    animation-iteration-count: infinite;
  }

  &:after {
    content: '';
    position: absolute;
    top: 7.0625rem;
    left: 7.375rem;
    width: 11.875rem;
    height: 10.375rem;
    background: url('/image/logo-wireframe.svg') no-repeat 0 0;
    background-size: 100%;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const DownloadBlock = styled.div`
  box-sizing: border-box;
  position: relative;
  padding-top: 4.375rem;
  padding-bottom: 7.5rem;
  min-height: 65vh;
  height: 100%;
`;
