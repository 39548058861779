import { createGlobalStyle } from 'styled-components';
import { Colors, Fonts } from './Variables';

export const mediaLaptopXSmall = '@media screen and (max-width: 1100px)';
export const mediaLaptopSmall = '@media screen and (max-width: 1280px)';
export const mediaLaptopMedium = '@media screen and (max-width: 1366px)';
export const mediaPhone = '@media screen and (max-width: 495px)';
export const mediaHeaderCollapse = '@media screen and (max-width: 636px)';
export const mediaGeneralAdaptive = '@media screen and (max-width: 848px)';
export const mediaMobile = '@media screen and (max-width: 960px)';
export const mediaDesktop = '@media screen and (min-width: 961px)';
export const mediaDesktopUltraWide = '@media screen and (min-width: 3440px)';

export const GlobalLayoutStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }

  html {
    font-size: 16px;
  }

  body {
    margin: 0;
    background-color: ${Colors.base1};
    color: ${Colors.base3};
    font-family: ${Fonts.OpenSans};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.2;
  }
  
  p {
    line-height: 1.5;
  }

  img {
    max-width: 100%;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      position: relative;
      padding-left: 1.275rem;
      font-size: 1rem;

      &:before {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-left: -1.275rem;
        margin-right: 0.2375rem;
        content: '';
        border-radius: 8px;
        background-color: ${Colors.base3};
        vertical-align: middle;
        transform: scale(0.375);
      }
    }
  }
  
  .highlighted {
    background-color: ${Colors.primary};
    color: ${Colors.base1};
  }
  
  ${mediaPhone} {
    .phone-hide {
      display: none;
    }
  }

  ${mediaMobile} {
    .mobile-hide {
      display: none;
    }
  }
  
  ${mediaDesktop} {
    .desktop-hide {
      display: none;
    }
  }
`;
