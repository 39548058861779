import React from 'react';
import styled from 'styled-components';
import { ReactComponent as OkMark } from '@Static/image/icon/on.svg';

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 0.625rem;
  }
`;

export const NumericList = styled.ol`
  list-style: decimal inside;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 0.625rem;
    p {
      display: inline;
    }
  }
`;

export const OnListItem: React.FC<React.PropsWithChildren> = ({ children }) => (
  <li>
    <OkMark />
    {children}
  </li>
);

export const OnList = styled(List)`
  li:before {
    display: none;
  }
  
  svg {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: -1.275rem;
    margin-right: 0.2375rem;
    vertical-align: middle;
  }
`;
