import { Arch } from '@Shared/Component';
import React, { useEffect } from 'react';
import { PageContainer } from '@Shared/Layout';
import { Heading2, Description, ExternalLink } from '@Shared/Style';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

const appURL = (service: string, params: string): string => `gifox://integration/oauth/${service}?${normalizeParams(params)}`;

// While using token response_type - OAuth provider will use hashes instead union symbols for some URL parameters.
const normalizeParams = (urlParams: string): string => urlParams
  .replace("?", "")
  .replace("#", "&")
  .split("&")
  .map((pair) => pair.replace("#", "").split("="))
  .map((pair) => pair.join("="))
  .join("&");

const serviceName = (serviceId: string): string => {
  switch (serviceId) {
    case "dropbox": return "Dropbox";
    case "google-drive": return "Google Drive";
    case "imgur": return "Imgur";
    // In other cases we're returning capitalized name.
    default: return serviceId.charAt(0).toUpperCase() + serviceId.slice(1);
  }
};

export const IntegrationCallbackPage: React.FC = () => {
  const location = useLocation();
  const { service } = useParams<{ service: string }>();
  const parameters = window.location.href.slice(window.location.href.indexOf(location.pathname) + location.pathname.length);

  useEffect(() => {
    setTimeout(() => {
      if (service.length && parameters.length) {
        window.location.assign(appURL(service, parameters));
      }
    }, 600);
  }, [service, parameters]);

  return (
    <>
      <Helmet>
        <title>Share Integration – Gifox 🦊</title>
      </Helmet>
      <IntegrationBlock>
        <Arch top="16rem" />
        <PageContainer textAlign="center">
          <IntegrationLogo />
          <Heading2>Integrating with {serviceName(service)}</Heading2>
          <Description>
            <ExternalLink href={appURL(service, parameters)}>Opening Gifox…</ExternalLink>
          </Description>
        </PageContainer>
      </IntegrationBlock>
    </>
  );
};

const IntegrationLogo = styled.div`
  position: relative;
  width: 26.625rem;
  height: 24.5rem;
  margin: 0 auto;
  transition: clip-path 1s ease-in-out;
  clip-path: polygon(0 0, 0 12.375rem, 0 24.5rem, 13.25rem 24.5rem, 13.4375rem 24.5rem, 26.625rem 24.5rem, 26.625rem 12.375rem, 26.625rem 0, 17.5rem 0, 13.3125rem 0, 9.1875rem 0);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('/image/logo-backplate.svg') no-repeat 0 0;
    background-size: 100%;
    animation: 10s rotate;
    animation-iteration-count: infinite;
  }

  &:after {
    content: '';
    position: absolute;
    top: 7.0625rem;
    left: 7.375rem;
    width: 11.875rem;
    height: 10.375rem;
    background: url('/image/logo-wireframe.svg') no-repeat 0 0;
    background-size: 100%;
  }

  @keyframes rotate {
    0% {
      transform: rotate(-10deg);
    }
    50% {
      transform: rotate(5deg);
    }
    100% {
      transform: rotate(-10deg);
    }
  }
`;

const IntegrationBlock = styled.div`
  box-sizing: border-box;
  position: relative;
  padding-top: 4.375rem;
  padding-bottom: 7.5rem;
  min-height: 65vh;
  height: 100%;
`;
