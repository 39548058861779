import React, { ChangeEvent, useCallback, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Colors, Fonts, mediaPhone } from '../Style';

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  value: string;
  onTextChange: (value: string) => void;
};

export const TextInput: React.FC<TextInputProps> = ({ value, onTextChange, ...props }) => {
  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onTextChange(e.currentTarget.value);
    },
    [onTextChange],
  );

  return <Input value={value} onChange={onInputChange} {...props} />;
};

const Input = styled.input`
  display: block;
  box-sizing: border-box;
  height: 2.5rem;
  width: 20rem;
  max-width: 100%;
  padding: 0.75rem 0.75rem 0.625rem;
  background: rgba(61, 61, 61, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.27);
  border-radius: 0.375rem;
  color: ${Colors.base5};
  font-family: ${Fonts.Spartan};
  font-size: 0.875rem;
  font-weight: bold;
  outline: 0;
  -webkit-appearance: none;

  &:focus {
    border-color: ${Colors.primary};
  }
  
  ${mediaPhone} {
    width: 100%;
  }
`;
