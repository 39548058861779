import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Colors, Fonts, mediaPhone } from '../Style';

type AccordionProps = {
  title: string;
  open?: boolean;
  customIconComponent?: React.ReactChild;
  noRightPadding?: boolean;
};

export const Accordion: React.FC<AccordionProps & React.PropsWithChildren> = ({ title, open = false, noRightPadding = false, customIconComponent, children }) => {
  const [state, setState] = useState(open);
  const toggleHandler = useCallback(() => {
    setState(!state);
  }, [setState, state]);

  useEffect(() => {
    setState(open);
  }, [open]);

  return (
    <AccordionBlock open={state}>
      <AccordionTitle onClick={toggleHandler} customIcon={!!customIconComponent}>
        {title}
        {customIconComponent && <AccordionTitleIcon>{customIconComponent}</AccordionTitleIcon>}
      </AccordionTitle>
      <AccordionContent noRightPadding={noRightPadding}>{children}</AccordionContent>
    </AccordionBlock>
  );
};

const AccordionTitle = styled.div<{ customIcon: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0.75rem 6.25rem 0.75rem 2.5rem;
  font-family: ${Fonts.Spartan};
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 0.9375rem;
  cursor: pointer;
  height: 5rem;

  &:before {
    ${({ customIcon }) => (customIcon ? 'display: none;' : '')};
    position: absolute;
    content: '';
    top: 2.4375rem;
    right: 2.5rem;
    width: 1.875rem;
    height: 3px;
    background-color: ${Colors.base2};
  }

  &:after {
    ${({ customIcon }) => (customIcon ? 'display: none;' : '')};
    position: absolute;
    content: '';
    top: 1.5625rem;
    right: 3.275rem;
    width: 3px;
    height: 1.875rem;
    background-color: ${Colors.base2};
    transition: 0.3s ease-in-out;
  }
  
  ${mediaPhone} {
    font-size: 1.1rem;
  }
`;

const AccordionTitleIcon = styled.div`
  position: absolute;
  top: 1.5625rem;
  right: 2.5rem;
  
  ${mediaPhone} {
    font-size: 1.35rem;
  }
`;

const AccordionContent = styled.div<{ noRightPadding: boolean }>`
  box-sizing: border-box;
  margin-top: -1.25rem;
  padding: 0 ${({ noRightPadding }) => (noRightPadding ? '2.5rem' : '6.25rem')} 1.5625rem 2.5rem;
`;

const AccordionBlock = styled.div<{ open: boolean }>`
  box-sizing: border-box;
  border: 1px solid ${Colors.base2};
  border-bottom-width: 0;
  max-width: 48.75rem;
  margin: 0 auto;
  color: ${Colors.base3};
  max-height: ${({ open }) => (open ? 'auto' : '5rem')};
  overflow: hidden;
  text-align: left;
  transition: 0.3s ease-in-out;

  &:first-of-type {
    border-top-left-radius: 0.9375rem;
    border-top-right-radius: 0.9375rem;
  }
  &:last-of-type {
    border-bottom-left-radius: 0.9375rem;
    border-bottom-right-radius: 0.9375rem;
    border-bottom-width: 1px;
  }

  ${AccordionContent} {
    opacity: ${({ open }) => (open ? '1' : '0')};
    transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(6.25rem)')};
    transition: 0.3s ease-in-out;
  }

  ${AccordionTitle}:after {
    ${({ open }) => (open
    ? `
      opacity: 0;
      transform: scale(0);
    `
    : '')};
  }
`;
