import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { postPaddleCheckout } from './Remote';

const useCheckoutQuery = (params: { productId: number; quantity: number }) => useQuery(['paddle_checkout'], () => postPaddleCheckout(params), {
  enabled: false,
  keepPreviousData: false,
  retry: false,
});

export const useCheckout = (params: { productId: number; quantity: number }) => {
  const query = useCheckoutQuery(params);
  const error = query.isError ? getNetworkError(query.error) : null;

  const doCheckout = async () => {
    const response = await query.refetch();
    return response.data?.data.payLink || '';
  };

  return { doCheckout, query, error };
};

export type NetworkError = {
  message: string;
  type: NetworkErrorType;
};

const getNetworkError = (error: unknown): NetworkError => {
  const message = (error as AxiosError<{ message: string }>)?.response?.data?.message || (error as Error).message;
  switch (message?.toLowerCase()) {
    case 'network error':
      return {
        message: 'Check your internet connection or try again later.',
        type: NetworkErrorType.Network,
      };
    default:
      return {
        message,
        type: NetworkErrorType.Unknown,
      };
  }
};

export enum NetworkErrorType {
  Network,
  Unknown,
}
