import React from 'react';
import styled from 'styled-components';
import { Description, mediaLaptopSmall, mediaMobile, Sizes, Spacings } from '@Shared/Style';
import { Image } from './Image';

export const CompanyList: React.FC<React.PropsWithChildren> = ({ children }) => (
  <CompanyContainer>
    <Group>
      <Column>
        <Image src="/image/company/amazon.svg" />
        <Image src="/image/company/adobe.svg" />
        <Image src="/image/company/netflix.svg" />
      </Column>
      <Column>
        <Image src="/image/company/google.svg" />
        <Image src="/image/company/github.svg" />
      </Column>
      <Column>
        <Image src="/image/company/apple.svg" />
      </Column>
    </Group>
    <Content>
      {children}
    </Content>
    <Group>
      <Column>
        <Image src="/image/company/microsoft.svg" />
      </Column>
      <Column>
        <Image src="/image/company/slack.svg" />
        <Image src="/image/company/figma.svg" />
      </Column>
      <Column>
        <Image src="/image/company/dropbox.svg" />
        <Image src="/image/company/tesla.svg" />
        <Image src="/image/company/paypal.svg" />
      </Column>
    </Group>
  </CompanyContainer>
);

const CompanyContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 21.185rem;
  max-width: ${Sizes.containerWidth};
  margin: 0 auto;
  
  ${Description} {
    ${mediaLaptopSmall} {
      br {
        display:  none;
      }
    }
  }
`;

const Group = styled.div`
  display: flex;
  width: 30%;
  flex-flow: row nowrap;
  justify-content: space-between;

  ${mediaMobile} {
    &:first-of-type {
      margin-left: -15%;
    }
    &:last-of-type {
      margin-right: -15%;
    }
  }
`;

const Content = styled.div`
  display: block;
  padding: 0 ${Spacings.spacingVerticalMedium};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 3.75rem;
  
  img {
    width: 4.8125rem;
    height: 4.8125rem;
  }
`;
