import { ERoute } from '@App/Router';
import { useClickOutside } from '@Shared/Hook/ClickOutside';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Button, EButtonVariant } from '@Shared/Component';
import { useNavigate } from '../Hook/Navigate';
import { Colors, Fonts, Link, mediaHeaderCollapse, mediaPhone, Sizes, Spacings } from '../Style';

export const Header: React.FC = () => {
  const headerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isNavigationOpen, setNavigationOpen] = useState(false);
  const [previousPath, setPreviousPath] = useState<string | null>(null);
  const links = [
    {
      key: ERoute.Changelog,
      name: "Changelog",
    },
    {
      key: ERoute.Docs,
      name: "Docs",
    },
    {
      key: ERoute.Buy,
      name: "Pricing",
    },
  ];

  const handleClickOutside = useCallback(() => {
    setNavigationOpen(false);
  }, []);

  const toggleNavigation = useCallback(() => {
    setNavigationOpen(!isNavigationOpen);
  }, [isNavigationOpen]);

  useClickOutside(headerRef, handleClickOutside);

  useEffect(() => {
    if (previousPath && (previousPath != location.pathname)) {
      setNavigationOpen(false);
      setPreviousPath(location.pathname);
    } else {
      setPreviousPath(location.pathname);
    }
  }, [location, previousPath]);

  return (
    <HeaderBlock ref={headerRef}>
      <HeaderContainer>
        <LogoLink to="/">
          <img src="/image/logo.svg" alt="" />
          Gifox
        </LogoLink>
        <NavigationDesktop onClick={handleClickOutside}>
          {links.map((link) => (
            <Link key={link.key} $active={location.pathname.startsWith(link.key)} to={link.key}>{link.name}</Link>
          ))}
          <Button variant={EButtonVariant.Outline} onClick={navigate(ERoute.Download)} className="phone-hide">
            Try for free
          </Button>
        </NavigationDesktop>
        <NavigationToggle isOpen={isNavigationOpen} onClick={toggleNavigation}>
          <Ham />
          <Bur />
          <Ger />
        </NavigationToggle>
      </HeaderContainer>
      <NavigationMobile isOpen={isNavigationOpen} onClick={handleClickOutside}>
        {links.map((link) => (
          <Link key={link.key} $active={location.pathname.startsWith(link.key)} to={link.key}>{link.name}</Link>
        ))}
      </NavigationMobile>
    </HeaderBlock>
  );
};

const HeaderBlock = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto 5rem;
  background-color: ${Colors.base1};

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${mediaHeaderCollapse} {
    position: fixed;
    top: -1px;
    padding-top: 1px;
    z-index: 1000;
    background: linear-gradient(180deg, ${Colors.base1} 0%, ${Colors.base1}B3 100%);
    backdrop-filter: blur(30px);
    border-bottom: 1px solid ${Colors.base1Dark}80;
    
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background: ${Colors.base2}80;
      height: 1px;
    }
  }
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  height: 8.8125rem;
  padding: 0 ${Spacings.spacingHorizontalMedium};
  justify-content: space-between;
  align-items: center;
  max-width: ${Sizes.containerWidth};
  margin: 0 auto;

  ${mediaHeaderCollapse} {
    height: 4.125rem;
    padding: 0 ${Spacings.spacingHorizontalMedium};
  }
`;

const LogoLink = styled(Link)`
  font-size: 1.875rem;
  color: ${Colors.base3};
  font-family: ${Fonts.Spartan};
  font-weight: bold;
  text-decoration: none;
  border-bottom: 0;

  img {
    margin-right: 0.75rem;
    margin-top: -0.4rem;
  }

  &:hover {
    border-bottom: 0;
    color: ${Colors.base3};
    text-decoration: none;
  }

  ${mediaHeaderCollapse} {
    font-size: 1.125rem;

    img {
      width: 1.875rem;
    }
  }
`;

const NavigationDesktop = styled.nav`
  display: flex;
  align-items: center;
  flex-flow: row wrap;

  a {
    margin-right: 1.875rem;
    color: ${Colors.base3Lighten};
    text-decoration: none;
    font-weight: 600;
    font-size: 0.875rem;
    border-bottom: 0;

    &:hover {
      color: ${Colors.primary};
      border-bottom: 0;
      text-decoration: none;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  ${mediaHeaderCollapse} {
    display: none;
  }
`;

const NavigationMobile = styled.nav<{ isOpen: boolean }>`
  display: none;
  position: relative;
  height: 0;
  width: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;

  ${mediaHeaderCollapse} {
    display: block;
  }

  a {
    display: block;
    width: 100%;
    text-align: left;
    color: ${Colors.base3Lighten};
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    border-bottom: 0;
    padding: 1.275rem 2rem;
    margin: 0;

    &:hover {
      color: ${Colors.primary};
      border-bottom: 0;
      text-decoration: none;
    }

    &:last-child {
      margin-right: 0;
    }

    ${mediaPhone} {
      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  ${({ isOpen }) => isOpen && css`
    height: 100%;
    top: 100%;
    opacity: 1;
    visibility: visible;
  `}
`;

const MenuStripe = styled.div`
  position: absolute;
  background-color: ${Colors.base3};
  left: 0;
  right: 0;
  height: 2px;
  transition: 0.3s ease-in-out;
`;

const Ham = styled(MenuStripe)`
  top: 10px;
`;

const Bur = styled(MenuStripe)`
  top: 17px;
`;

const Ger = styled(MenuStripe)`
  top: 24px;
`;

const NavigationToggle = styled.button<{ isOpen: boolean }>`
  display: none;
  position: relative;
  width: 1.225rem;
  height: 2.325rem;
  border: 0;
  outline: none;
  background-color: transparent;

  ${mediaHeaderCollapse} {
    display: block;
  }

  ${({ isOpen }) => isOpen && css`
    ${Ham} {
      transform: rotate(45deg) translate(5px, 5px);
    }
    ${Bur} {
      opacity: 0;
    }
    ${Ger} {
      transform: rotate(-45deg) translate(5px,-4.5px);
    }
  `}
`;
