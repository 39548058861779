import { declination } from '@Helper';
import React, { ChangeEvent, useCallback } from 'react';
import styled from 'styled-components';
import { Colors, Fonts, mediaMobile } from '../Style';

type InputCountProps = {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  declinations?: [string, string, string];
};

export const CountInput: React.FC<InputCountProps> = ({ value, onChange, min, max, declinations }) => {
  const onDecrease = useCallback(() => {
    if (min === undefined || value - 1 >= min) {
      onChange(value - 1);
    }
  }, [onChange, value, min]);

  const onIncrease = useCallback(() => {
    if (max === undefined || value + 1 <= max) {
      onChange(value + 1);
    }
  }, [onChange, value, max]);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = Number(e.currentTarget.value);
      if ((min === undefined || newValue >= min) && (max === undefined || newValue <= max)) {
        onChange(newValue);
      }
    },
    [onChange, min, max],
  );

  return (
    <Container>
      {declinations ? <span>per</span> : null}
      <Input value={value} type="numeric" onChange={onInputChange} />
      {declinations ? <span>{declination(value, declinations)}</span> : null}
      <ControlButton onClick={onIncrease}><Add /></ControlButton>
      <ControlButton onClick={onDecrease}><Subtract /></ControlButton>
    </Container>
  );
};

const Add: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="10.875" y="4.5" width="2.25" height="15" fill="currentColor" />
    <rect x="19.5" y="10.875" width="2.25" height="15" transform="rotate(90 19.5 10.875)" fill="currentColor" />
  </svg>
);

const Subtract: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="19.5" y="10.875" width="2.25" height="15" transform="rotate(90 19.5 10.875)" fill="currentColor" />
  </svg>
);

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 6px;
  padding: 0.5rem;
  height: 2.5rem;
  gap: 0.3125rem;

  span {
    width: 2.6875rem;
    font-family: ${Fonts.Spartan};
    font-size: 0.625rem;
    text-align: center;
    text-transform: uppercase;

    ${mediaMobile} {
      width: 100%;
    }
  }
  
  ${mediaMobile} {
    width: 100%;
  }
`;

const ControlButton = styled.button`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  background: rgba(255, 255, 255, 0.2);
  border: 0;
  border-radius: 6px;
  text-align: center;
  color: ${Colors.base1};
  font-family: ${Fonts.Spartan};
  font-size: 0.625rem;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.125s ease-in-out;
  
  svg {
    width: 0.625rem;
    height: 0.625rem;
  }
  
  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }

  &:active {
    background: rgba(255, 255, 255, 0.6);
    border-color: transparent;
    color: ${Colors.base1};
  }
  
  ${mediaMobile} {
    width: 100%;
  }
`;

const Input = styled.input`
  box-sizing: border-box;
  width: 2.6875rem;
  padding: 0;
  background: transparent;
  border: 0;
  color: ${Colors.base1};
  font-family: ${Fonts.Spartan};
  font-size: 0.875rem;
  font-weight: bold;
  text-align: center;
  outline: 0;
  -webkit-appearance: none;

  &:focus {
    border-color: ${Colors.primary};
  }
  
  ${mediaMobile} {
    width: 200%;
  }
`;
