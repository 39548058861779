import { useClickOutside } from '@Shared/Hook/ClickOutside';
import { Colors, Fonts, mediaDesktop, mediaHeaderCollapse, NavigatorLink } from '@Shared/Style';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

export const PageNavigator = styled.div`
  position: sticky;
  top: 2.25rem;
  min-width: 17.5rem;
  max-width: 17.5rem;

  a {
    display: block;
    margin-bottom: 1.75rem;
  }
`;

export const MobileNavigator: React.FC<{ entries: Record<string, { name: string }>, activeKey: string, linkBuilder: (key: string) => string }> = ({
  entries,
  activeKey,
  linkBuilder,
}) => {
  const navigatorRef = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(false);
  const handleToggle = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);
  const handleClickOutside = useCallback(() => {
    setOpen(false);
  }, []);

  useClickOutside(navigatorRef, handleClickOutside);

  return (
    <PageMobileNavigator className={isOpen ? 'open' : ''} ref={navigatorRef}>
      {!isOpen ? (
        <PageMobileNavigatorHead onClick={handleToggle}>
          {entries[activeKey]?.name}
        </PageMobileNavigatorHead>
      ) : null}
      <PageMobileNavigatorLinks>
        {Object.entries(entries)
          .map(([key, entry]) => (
            <NavigatorLink key={key} to={linkBuilder(key)} $active={activeKey === key} onClick={handleToggle}>
              {entry.name}
            </NavigatorLink>
          ))}
      </PageMobileNavigatorLinks>
    </PageMobileNavigator>
  );
};

export const PageMobileNavigator = styled.div`
  position: sticky;
  display: block;
  max-height: 3rem;
  margin: 0 0 1.85rem;
  overflow: hidden;
  width: 100%;
  top: 0;
  box-sizing: border-box;
  transition: 0.15s ease-in-out max-height;
  z-index: 100;
  background-color: ${Colors.accentLight};

  &:before {
    position: absolute;
    content: '';
    top: 1.5275rem;
    right: 1.75rem;
    width: 0.875rem;
    height: 1px;
    background-color: ${Colors.base2};
  }

  &:after {
    position: absolute;
    content: '';
    top: 1.1225rem;
    right: 2.15rem;
    width: 1px;
    height: 0.875rem;
    background-color: ${Colors.base2};
    transition: 0.3s ease-in-out;
  }

  &.open {
    max-height: 1000px;
    overflow-y: scroll;

    &:after {
      top: 1.5225rem;
      height: 0;
    }
  }

  &.sticky {
    box-shadow: 0 0 1rem rgba(43, 37, 34, 0.10);
  }

  a {
    display: block;
    margin-bottom: 1.75rem;
  }

  ${mediaDesktop} {
    display: none;
  }

  ${mediaHeaderCollapse} {
    top: 4.125rem;
  }
`;

export const PageMobileNavigatorHead = styled.div`
  display: block;
  font-size: 1rem;
  font-family: ${Fonts.Spartan};
  font-weight: bold;
  color: ${Colors.primary};
  text-decoration: none;
  padding: 1rem 1.75rem;
`;

export const PageMobileNavigatorLinks = styled.div`
  padding: 1rem 1.75rem;
  box-sizing: border-box;

  a:last-of-type {
    margin-bottom: 0;
  }
`;
