import React, { DOMAttributes } from 'react';
import styled from 'styled-components';
import { Colors, Fonts, Roundings } from '../Style';

export enum EButtonVariant {
  Gradient,
  Outline,
  Light,
  GradientLight,
  OutlineLight,
  GradientDark,
}

type ButtonProps = DOMAttributes<HTMLButtonElement> & {
  variant?: EButtonVariant;
  disabled?: boolean;
};

export const Button: React.FC<ButtonProps & React.HTMLAttributes<HTMLButtonElement>> = ({ variant = EButtonVariant.Outline, ...props }) => <StyledButton {...props} variant={variant} />;

const buttonStyleVariants: Record<string, string | any> = {
  [EButtonVariant.Gradient]: {
    color: Colors.base1,
    background: `${Colors.buttonGradient} border-box`,
    border: '2px solid transparent',
    boxShadow: '0px 1rem 1.5rem rgba(255, 57, 45, 0.16), 0px 0.375rem 0.75rem rgba(255, 57, 45, 0.16)',
    '&:hover': {
      background: `${Colors.buttonGradientHover} border-box`,
    },
    '&:active': {
      background: `${Colors.buttonGradientActive} border-box`,
    },
  },
  [EButtonVariant.Light]: {
    color: Colors.primary,
    background: `${Colors.base1} border-box`,
    border: '2px solid transparent',
    boxShadow: '0px 1rem 1.5rem rgba(255, 57, 45, 0.16), 0px 0.375rem 0.75rem rgba(255, 57, 45, 0.16)',
    '&:hover': {
      background: Colors.base1,
      color: Colors.primaryHover,
    },
    '&:active': {
      background: Colors.base1,
      color: Colors.primaryActive,
    },
  },
  [EButtonVariant.Outline]: {
    color: Colors.primary,
    background: 'transparent',
    border: `2px solid ${Colors.primary}`,
    '&:hover': {
      color: Colors.primaryHover,
      border: `2px solid ${Colors.primaryHover}`,
    },
    '&:active': {
      color: Colors.primaryActive,
      border: `2px solid ${Colors.primaryActive}`,
    },
  },
  [EButtonVariant.OutlineLight]: {
    color: Colors.primary,
    background: 'transparent',
    border: `2px solid ${Colors.primary}`,
    '&:hover': {
      color: Colors.base1Dark,
      border: `2px solid ${Colors.base1Dark}`,
    },
    '&:active': {
      color: Colors.primaryActive,
      border: `2px solid ${Colors.primaryActive}`,
    },
  },
};

buttonStyleVariants[EButtonVariant.GradientLight] = {
  ...buttonStyleVariants[EButtonVariant.Gradient] as object,
  boxShadow: '0px 1rem 1.5rem rgba(255, 57, 45, 0.16), 0px 0.375rem 0.75rem rgba(255, 57, 45, 0.16)',
};

buttonStyleVariants[EButtonVariant.OutlineLight] = {
  ...buttonStyleVariants[EButtonVariant.OutlineLight] as object,
  color: Colors.base1,
  borderColor: Colors.base1,
};

buttonStyleVariants[EButtonVariant.GradientDark] = {
  ...buttonStyleVariants[EButtonVariant.Gradient] as object,
  boxShadow: '0px 1rem 1.5rem rgba(0, 0, 0, 0.16), 0px 0.375rem 0.75rem rgba(0, 0, 0, 0.16)',
};

const StyledButton = styled.button<ButtonProps>`
  border-radius: ${Roundings.buttonRounding};
  padding: 0.6875rem 1.25rem 0.53125rem;
  font-family: ${Fonts.Spartan};
  font-size: 0.875rem;
  font-weight: bold;
  cursor: pointer;
  box-sizing: border-box;
  ${({ variant }) => buttonStyleVariants[variant || EButtonVariant.Gradient]}

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
`;
