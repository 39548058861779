import { ERoute } from '@App/Router';
import { Arch } from '@Shared/Component';
import React from 'react';
import styled from 'styled-components';
import { PageContainer } from '@Shared/Layout';
import { Heading2, Description, Link } from '@Shared/Style';
import { Helmet } from 'react-helmet-async';

export const CheckoutPage: React.FC = () => (
  <>
    <Helmet>
      <title>Checkout Complete – Gifox 🦊</title>
    </Helmet>
    <CheckoutBlock>
      <PageContainer textAlign="center" overflow="hidden">
        <Arch top={64} />
        <CheckoutLogo />
        <Heading2>Payment was successful!</Heading2>
        <Description>
          An email confirming the transaction and license has been sent to you. <br />
          <br /> You can <Link to={ERoute.Download}>download Gifox 2</Link> if you haven&apos;t done this yet.
        </Description>
      </PageContainer>
    </CheckoutBlock>
  </>
);

const CheckoutLogo = styled.div`
  position: relative;
  width: 26.625rem;
  height: 24.5rem;
  margin: 0 auto;
  transition: clip-path 1s ease-in-out;
  clip-path: polygon(0 0, 0 12.375rem, 0 24.5rem, 13.25rem 24.5rem, 13.4375rem 24.5rem, 26.625rem 24.5rem, 26.625rem 12.375rem, 26.625rem 0, 17.5rem 0, 13.3125rem 0, 9.1875rem 0);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('/image/logo-backplate.svg') no-repeat 0 0;
    background-size: 100%;
    animation: 120s rotate;
    animation-iteration-count: infinite;
  }

  &:after {
    content: '';
    position: absolute;
    top: 7.0625rem;
    left: 7.375rem;
    width: 11.875rem;
    height: 10.375rem;
    background: url('/image/logo-wireframe.svg') no-repeat 0 0;
    background-size: 100%;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const CheckoutBlock = styled.div`
  box-sizing: border-box;
  position: relative;
  padding-top: 4.375rem;
  padding-bottom: 7.5rem;
  min-height: 65vh;
  height: 100%;
`;
