import { ERoute } from '@App/Router';
import { remarkFlattenImage } from '@Helper/Remark';
import { Image, withAnchor } from '@Shared/Component/index';
import { useScrollToElement } from '@Shared/Hook/ScrollToElement';
import { List, NumericList } from '@Shared/Layout';
import { ExternalSilentLink, GenericHashLink, Heading1, Heading2, Heading3, Heading4, KeyShortcut, Quote, Table } from '@Shared/Style';
import React, { useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import gfm from 'remark-gfm';
import remarkSlug from 'remark-slug';
import styled from 'styled-components';

export const MarkdownContent = ({ children }: { children: string }) => {
  const transformImageUri = useCallback((uri: string) => {
    if (uri.startsWith('../../image/documentation/')) {
      return `/image${ERoute.Docs}/${uri.substr('../../image/documentation/'.length)}`;
    }

    return uri;
  }, []);

  return (
    <ReactMarkdown
      remarkPlugins={[gfm, remarkSlug, remarkFlattenImage]}
      rehypePlugins={[rehypeRaw]}
      components={{
        h1: Heading1,
        h2: AnchorHeading2,
        h3: AnchorHeading3,
        h4: Heading4,
        a: DocumentationLink,
        ul: List,
        ol: NumericList,
        kbd: KeyShortcut,
        img: Image,
        blockquote: Quote,
        table: Table,
        strong: Strong,
      }}
      transformImageUri={transformImageUri}
    >
      {children}
    </ReactMarkdown>
  );
};

const h2 = styled(Heading2)`
  margin: 3rem 0 1.2575rem;
`;

const h3 = styled(Heading3)`
  margin: 2.75rem 0 0.75rem;
`;

const Strong = styled.span`
  font-weight: 700;
`;

export const AnchorHeading2 = withAnchor(h2);
export const AnchorHeading3 = withAnchor(h3);

const DocumentationLink: React.FC<React.HTMLProps<HTMLAnchorElement>> = (props) => {
  const { scrollToElement } = useScrollToElement();
  const { href } = props;
  const isSameOrigin = href?.startsWith(window.location.origin) || href?.startsWith('https://gifox.app') || href?.startsWith('/');
  const isAnchor = href?.startsWith('#');
  const onClick = useCallback((e: React.SyntheticEvent<HTMLAnchorElement | undefined>) => {
    if (isAnchor && href) {
      scrollToElement(href);
      e.preventDefault();
    }
  }, [href, isAnchor, scrollToElement]);

  if (isSameOrigin && href) {
    let to = href;
    try {
      const url = new URL(href);
      to = href.replace(url.origin, '');
    } catch (e) {
      console.warn(e);
    }
    return <GenericHashLink to={to} {...props} />;
  }

  return <ExternalSilentLink {...props as any} onClick={onClick} target={isSameOrigin ? '_self' : '_blank'} />;
};
