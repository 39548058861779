import React from 'react';
import { App } from '@App';
import { createRoot } from 'react-dom/client';
import 'reset-css';

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<App />);
}
