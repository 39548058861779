declare function gtag(type: string, action: string, payload?: {
  event_category?: string;
  event_label?: string;
  value?: number;
}): void;

type ReachGoalPayload = { category?: string, label?: string, value?: number };

export const reachGoal = (goal: string, payload?: ReachGoalPayload) => () => {
  gtag('event', goal, payload && {
    ...(payload.category ? { event_category: payload.category } : {}),
    ...(payload.label ? { event_label: payload.label } : {}),
    ...(payload.value ? { value: payload.value } : {}),
  });
};

export const reachGoalOnclick = (goal: string, payload?: ReachGoalPayload) => () => {
  reachGoal(goal, payload);
};
