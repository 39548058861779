import { useCallback, useState } from 'react';

export const useScrollToElement = (offset = 40) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollToElement = useCallback((selector: string | null) => {
    if (!selector) { return; }
    setIsScrolling(true);
    setTimeout(() => setIsScrolling(false), 800);
    setTimeout(() => {
      const yCoordinate = (document.querySelector(selector)
        ?.getBoundingClientRect().top ?? 0) + window.scrollY;
      window.scrollTo({
        top: yCoordinate - offset,
        behavior: 'smooth',
      });
    }, 60);
  }, [offset]);

  return {
    scrollToElement,
    isScrolling,
  };
};
