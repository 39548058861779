import { ERoute } from '@App/Router';
import { Button, EButtonVariant, Arch } from '@Shared/Component';
import { useNavigate } from '@Shared/Hook/Navigate';
import React from 'react';
import styled from 'styled-components';
import { PageContainer } from '@Shared/Layout';
import { Heading2, Description, LinkHighlighted } from '@Shared/Style';
import { Helmet } from 'react-helmet-async';
import { ReactComponent as Backplate404 } from '@Static/image/logo-backplate-404.svg';

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>404 Page not Found – Gifox 🦊</title>
      </Helmet>
      <NotFoundBlock>
        <Arch top="16rem" />
        <PageContainer textAlign="center">
          <LogoWrapper>
            <Backplate404 />
            <NotFoundLogo />
          </LogoWrapper>
          <Heading2>Did you get lost?</Heading2>
          <Description>
            The page you are looking for doesn’t exist or has moved. <br />
            Go to the main page or see common <LinkHighlighted to={ERoute.FAQ}>questions & answers</LinkHighlighted>.
          </Description>
          <Button variant={EButtonVariant.GradientLight} onClick={navigate(ERoute.Home)}>Go to home page</Button>
        </PageContainer>
      </NotFoundBlock>
    </>
  );
};

const LogoWrapper = styled.div`
  position: relative;
  width: 26.75rem;
  height: 25.25rem;
  margin: 0 auto;

  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 26.813rem;
    height: 25.375rem;

    .numbers {
      animation: 10s linear 0s infinite numbers-slide;
    }
  }

  @keyframes numbers-slide {
    0% {
      transform: translate(9.8125rem, 0);
    }
    100% {
      transform: translate(-11.375rem, 0);
    }
  }
`;

const NotFoundLogo = styled.div`
  position: relative;
  display: inline-block;
  width: 11.875rem;
  height: 10.375rem;
  background: url('/image/logo-wireframe.svg') no-repeat 50% 50%;
  background-size: 99%;
  margin: 7.0625rem auto 0;
`;

const NotFoundBlock = styled.div`
  box-sizing: border-box;
  position: relative;
  padding-top: 4.375rem;
  padding-bottom: 7.5rem;
  min-height: 65vh;
  height: 100%;
`;
