import { API_BASE } from '@Constant';
import axios from 'axios';

type GetDMGParams = {
  version: string;
};

type GetDMGResponse = {
  success: boolean;
  url: string;
};

export const getDMG = async (params: GetDMGParams): Promise<string | null> => {
  try {
    const response = await axios.get<GetDMGResponse>(`${API_BASE}/dmg?version=${encodeURIComponent(params.version)}`);
    if (response.data.success) { return response.data.url; }
    return null;
  } catch (e) {
    return null;
  }
};
