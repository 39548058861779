import React from 'react';
import styled, { css } from 'styled-components';
import { Colors, Description, Fonts, Heading2, mediaLaptopXSmall } from '../Style';
import { Button, EButtonVariant } from './Button';

export type PricingOptionProps = {
  title: string;
  price: string;
  buttonTitle: string;
  buttonDisabled?: boolean;
  onClick: () => void;
};

export const PricingOption: React.FC<React.PropsWithChildren<PricingOptionProps>> = ({ title, price, buttonTitle, onClick, children, buttonDisabled }) => (
  <PricingOptionCard>
    <div>
      <PricingOptionTitle>{title}</PricingOptionTitle>
      <PricingOptionValue>{price}</PricingOptionValue>
      <PricingOptionContent>{children}</PricingOptionContent>
    </div>
    <Button variant={EButtonVariant.Outline} onClick={onClick} disabled={buttonDisabled} style={{ width: '100%' }}>
      {buttonTitle}
    </Button>
  </PricingOptionCard>
);

export const PricingOptionBasicStyles = css`
  position: relative;
  top: 0;
  background-color: ${Colors.base1};
  color: ${Colors.base3};
  border-radius: 1.875rem;
  padding: 4.7875rem 2.5rem 81px;
  box-shadow: 0px 12px 24px rgba(151, 151, 151, 0.25);
  text-align: left;
  box-sizing: border-box;
  transition: top 0.175s ease-out;

  &:hover {
    top: -3px;
  }

  ${mediaLaptopXSmall} {
    width: 100%;
    margin-right: 0;
    max-width: 480px;

    &:hover {
      top: 0;
    }
  }

  &:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: linear-gradient(270deg, #FFC6AD 0%, #FFDBCF 8.06%, #FFEEEA 20.83%, #F7F7F7 68.75%), linear-gradient(23.6deg, #EDEDED 2.28%, #FEFEFE 100.98%);
    padding-right: 1.675rem;

    ${mediaLaptopXSmall} {
      padding-right: 2.5rem;
      border-top-right-radius: 1.875rem;
      border-bottom-right-radius: 1.875rem;
      margin-bottom: -1.875rem;
      background: linear-gradient(0deg, #FFC6AD 0%, #FFDBCF 8.06%, #FFEEEA 20.83%, #F7F7F7 68.75%), linear-gradient(23.6deg, #EDEDED 2.28%, #FEFEFE 100.98%);
    }
  }

  &:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: linear-gradient(90deg, #FFC6AD 0%, #FFDBCF 8.06%, #FFEEEA 20.83%, #F7F7F7 68.75%), linear-gradient(23.6deg, #EDEDED 2.28%, #FEFEFE 100.98%);
    padding-left: 1.675rem;

    ${mediaLaptopXSmall} {
      padding-left: 2.5rem;
      border-top-left-radius: 1.875rem;
      border-bottom-left-radius: 1.875rem;
      margin-top: -1.875rem;
      background: linear-gradient(180deg, #FFC6AD 0%, #FFDBCF 8.06%, #FFEEEA 20.83%, #F7F7F7 68.75%), linear-gradient(23.6deg, #EDEDED 2.28%, #FEFEFE 100.98%);
    }
  }
  
  ${mediaLaptopXSmall} {
    padding: 2.5rem 1.725rem;
    min-height: 20rem;

    &:first-of-type {
      padding-right: 1.725rem;
      padding-bottom: 4.7875rem;
    }

    &:last-of-type {
      padding-left: 1.725rem;
      padding-top: 4.7875rem;
    }
  }
`;

const PricingOptionCard = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 21.25rem;
  height: 31.625rem;

  ${PricingOptionBasicStyles};
`;

const PricingOptionTitle = styled.div`
  font-family: ${Fonts.Spartan};
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1.875rem;
`;

const PricingOptionValue = styled(Heading2)`
  margin: 0;
`;

const PricingOptionContent = styled.div`
  margin: 1.875rem 0 0;

  ${Description} {
    margin-top: -0.125rem;
  }
`;
