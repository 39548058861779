import React, { useContext, useState, useEffect } from 'react';
import { fetchCurrentRelease } from '@Constant/Release';
import gettingStarted from '@Docs/Documentation/Getting Started.md';
import usingGifox from '@Docs/Documentation/Using Gifox.md';
import recording from '@Docs/Documentation/Recording.md';
import converting from '@Docs/Documentation/Converting.md';
import editing from '@Docs/Documentation/Editing.md';
import exporting from '@Docs/Documentation/Exporting.md';
import sharing from '@Docs/Documentation/Sharing.md';
import shortcuts from '@Docs/Documentation/Shortcuts.md';
import faq from '@Docs/Documentation/FAQ.md';

type DocumentationContextState = {
  documentation: DocumentationDictionary;
  setKey: (key: string) => void;
};

const initialDocumentation: DocumentationDictionary = {
  'getting-started': {
    name: 'Getting Started',
    title: 'Create high-quality animated GIF – Gifox Docs 🦊',
    description: 'Get started with Gifox on how to record screen and create animated GIFs – a foxy app for creating, converting and editing GIFs designed for your Mac!',
    content: null,
    isLoading: false,
    url: gettingStarted,
  },
  'using-gifox': {
    name: 'Using Gifox',
    title: 'Use app to create high-quality animated GIF – Gifox Docs 🦊',
    description: 'Get started with Gifox to record screen and create animated GIFs – a small foxy app for creating, converting and editing GIFs designed for your Mac!',
    content: null,
    isLoading: false,
    url: usingGifox,
  },
  recording: {
    name: 'Recording',
    title: 'Record screen as high-quality animated GIF – Gifox Docs 🦊',
    description: 'Record screen directly into high-quality animated GIFs using Gifox – a small foxy app for creating, converting and editing GIFs designed for your Mac!',
    content: null,
    isLoading: false,
    url: recording,
  },
  converting: {
    name: 'Converting',
    title: 'Convert video or image to high-quality GIF – Gifox Docs 🦊',
    description: 'Convert videos (MP4, MOV) and image sequences (JPG, PNG) into high-quality animated GIFs using Gifox – a small foxy app designed for your Mac!',
    content: null,
    isLoading: false,
    url: converting,
  },
  editing: {
    name: 'Editing',
    title: 'Edit, crop, split and resize animated GIF – Gifox Docs 🦊',
    description: 'Edit, crop, split, trim, resize and scale animated GIFs using Gifox – a small foxy app for creating, converting and editing GIFs designed for your Mac.',
    content: null,
    isLoading: false,
    url: editing,
  },
  exporting: {
    name: 'Exporting',
    title: 'Export, compress and save animated GIF – Gifox Docs 🦊',
    description: 'Export, compress and save high-quality animated GIFs using Gifox – a foxy app for creating, converting and editing GIFs designed for your Mac.',
    content: null,
    isLoading: false,
    url: exporting,
  },
  sharing: {
    name: 'Sharing',
    title: 'Share GIF to Dropbox, Imgur and Google Drive – Gifox Docs 🦊',
    description: 'Upload GIFs to Dropbox, Imgur, Slack and more services directly from Gifox – a foxy app for creating, converting and editing GIFs designed for your Mac.',
    content: null,
    isLoading: false,
    url: sharing,
  },
  shortcuts: {
    name: 'Shortcuts',
    title: 'Use shortcut to create animated GIF – Gifox Docs 🦊',
    description: 'Use system shortcuts to record screen directly into high-quality animated GIFs using with Gifox – a small foxy app designed for your Mac.',
    content: null,
    isLoading: false,
    url: shortcuts,
  },
  FAQ: {
    name: 'FAQ',
    title: 'How to create high-quality animated GIF – Gifox FAQ 🦊',
    description: 'Frequently asked questions about using Gifox and creating high-quality animated GIFs from screen recordings, videos, and image sequences.',
    content: null,
    isLoading: false,
    url: faq,
  },
} as const;

export const DocumentationContext = React.createContext<DocumentationContextState>({
  documentation: initialDocumentation,
  setKey: () => {},
});

export const DocumentationContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [currentKey, setCurrentKey] = useState('getting-started');
  const [dictionary, setDictionary] = useState<DocumentationDictionary>(initialDocumentation);
  const { variables, isLoading } = useDocumentationVariables();

  const contextValue: DocumentationContextState = {
    documentation: dictionary,
    setKey: setCurrentKey,
  };

  useEffect(() => {
    function prepareDocument(key: string) {
      if (isLoading) {
        return;
      }

      try {
        const document = dictionary[key];
        if (!document.isLoading && document.content == null) {
          fetch(document.url)
            .then((res) => res.text())
            .then((markdown) => {
              document.content = interpolate(markdown, variables);
            })
            .finally(() => {
              document.isLoading = false;
              setDictionary((prevState) => ({
                ...prevState,
                [key]: document,
              }));
            });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Unable to fetch documents", e);
      }
    }

    prepareDocument(currentKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentKey, dictionary, variables, isLoading]);

  return <DocumentationContext.Provider value={contextValue}>{children}</DocumentationContext.Provider>;
};

export const useDocumentationContext = (): DocumentationContextState => useContext(DocumentationContext);

export type DocumentationVariables = {
  GIFOX_VERSION: string,
  GIFOX_DEPLOYMENT_TARGET: string,
};

type DocumentationDictionaryValue = {
  name: string;
  title: string;
  description: string;
  content: string | null;
  isLoading: boolean;
  url: string;
};

export type DocumentationDictionary = Record<string, DocumentationDictionaryValue>;

const useDocumentationVariables = (): {
  variables: DocumentationVariables,
  isLoading: boolean;
} => {
  const [isLoading, setIsLoading] = useState(true);
  const [variables, setVariables] = useState({
    GIFOX_VERSION: "",
    GIFOX_DEPLOYMENT_TARGET: "",
  });

  useEffect(() => {
    fetchCurrentRelease()
      .then((release) => {
        setVariables({
          GIFOX_VERSION: release.version,
          GIFOX_DEPLOYMENT_TARGET: release.minimumSystemName,
        });
        setIsLoading(false);
      });
  }, []);

  return {
    variables,
    isLoading,
  };
};

const interpolate = (string: string, params: Record<string, string>) => {
  const regexTemplate = /\${(\S+)}/g;
  return string.replaceAll?.(regexTemplate, (value, captureGroup) => params[captureGroup] || captureGroup) || string;
};
