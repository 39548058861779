import React, { useContext, useState } from 'react';

export enum EFooterTheme {
  Light = 'light',
  Dark = 'dark',
  Accent = 'accent',
  Transparent = 'transparent',
}

type Theme = {
  footerTheme: EFooterTheme;
};

export const defaultTheme: Theme = {
  footerTheme: EFooterTheme.Dark,
};

export const lightFooterTheme: Theme = {
  footerTheme: EFooterTheme.Light,
};

export const accentFooterTheme: Theme = {
  footerTheme: EFooterTheme.Accent,
};

export const transparentFooterTheme: Theme = {
  footerTheme: EFooterTheme.Transparent,
};

type IThemeContext = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

export const ThemeContext = React.createContext<IThemeContext>({
  theme: defaultTheme,
  setTheme: () => {},
});

export const ThemeContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [value, setValue] = useState<Theme>(defaultTheme);

  const contextValue = {
    theme: value,
    setTheme: setValue,
  };

  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
};

export const useThemeContext = (): IThemeContext => useContext(ThemeContext);
