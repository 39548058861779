import { ERoute } from '@App/Router';
import { reachGoalOnclick } from '@Helper';
import React, { useEffect, useState } from 'react';
import { Block, PageContainer, Row, Spacing } from '@Shared/Layout';
import { Colors, Description, ExternalLink, Fonts, Heading2, Link, mediaDesktop, mediaDesktopUltraWide, mediaHeaderCollapse, mediaLaptopMedium, mediaMobile, mediaPhone, Spacings } from '@Shared/Style';
import { Button, EButtonVariant, CompanyList, Arch } from '@Shared/Component';
import styled, { CSSProperties } from 'styled-components';
import { useNavigate } from '@Shared/Hook/Navigate';
import { Helmet } from 'react-helmet-async';
import { TextLoop } from 'react-text-loop-next';
import { fetchCurrentRelease, Release, URL_APPSTORE, URL_ORIGINAL, URL_SETAPP } from '@Constant';
import { FeatureSection } from '@Shared/Component/FeatureSection';

export const HomePage: React.FC = () => {
  const [release, setRelease] = useState<Release | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCurrentRelease()
      .then((result) => setRelease(result))
      // eslint-disable-next-line no-console
      .catch((reason) => console.warn('Cannot fetch current release on home page!', reason));
  }, []);

  const title = 'Best GIF maker, recorder, converter, editor app – Gifox for Mac 🦊';
  const description = 'Gifox for Mac is a simple GIF maker app to create high-quality animated GIFs from screen and videos – record, convert, capture, edit, compress, optimize.';

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:locale" content="en" />
        <meta property="og:url" content="https://gifox.app/" />
        <meta property="og:image" content="https://gifox.app/image/gifox.gif" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={description} />
        <meta name="description" content={description} />
      </Helmet>
      <PageContainer textAlign="center">
        <HeadingTitle>
          The best GIF{' '}
          <TextLoop mask interval={1800}>
            <SloganBit>recording</SloganBit>
            <SloganBit>conversion</SloganBit>
            <SloganBit>editing</SloganBit>
            <SloganBit>sharing</SloganBit>
          </TextLoop>{' '}
          app.
        </HeadingTitle>
        <MainButtonsRow childSpacing={20}>
          <Button variant={EButtonVariant.Gradient} onClick={navigate(ERoute.Download)}>
            Download
          </Button>
          <Button variant={EButtonVariant.Outline} onClick={navigate(ERoute.Buy)}>
            Buy Now
          </Button>
        </MainButtonsRow>
        <HeadingDescription color={Colors.base2}>
          Also available on the <ExternalLink href={URL_APPSTORE} onClick={reachGoalOnclick('hero_app_store')}>Mac App Store</ExternalLink> and <ExternalLink href={URL_SETAPP} onClick={reachGoalOnclick('hero_setapp')}>Setapp</ExternalLink>.
          <br />
          {release ? (
            <>
              Works on {release.minimumSystemName} & higher.
              <br />
            </>
          ) : null}
          Looking for <ExternalLink href={URL_ORIGINAL}>original version</ExternalLink>?
        </HeadingDescription>
        <Hero />
        <Arch top="633px" />
        <Spacing height="41.525rem" />
        <CompanyList>
          <Heading2 margin="-1rem 0 0.6rem">Built for the best.</Heading2>
          <Description marginTop="0.4375rem" color={Colors.base3} maxWidth={424} margin="0 auto">
            Gifox is used by many teams in amazing companies <br />to exchange ideas, report bugs, help customers, <br />revive presentations and much more!
          </Description>
        </CompanyList>
        <Spacing height="2.875rem" />
      </PageContainer>
      <FeatureSection transparent direction="forward" imageSrc="/image/feature/selection.png">
        <Heading2>Make perfect<br />selection.</Heading2>
        <Description>Use free-area selection mode to capture any part of a screen, or window-snap mode to create pixel-perfect recordings of any app.</Description>
        <SectionLink to={`${ERoute.Docs}/recording#making-selection`}>Learn more about selection</SectionLink>
      </FeatureSection>
      <FeatureSection direction="reverse" imageSrc="/image/feature/dragndrop.png">
        <Heading2>Drop file in.<br />Drag GIF out.</Heading2>
        <Description>Drop videos and image sequences to convert them into GIFs, then drag them into other apps<br />to quickly send away.</Description>
        <SectionLink to={`${ERoute.Docs}/converting`}>Learn more about conversion</SectionLink>
      </FeatureSection>
      <FeatureSection direction="forward" imageSrc="/image/feature/editor.png">
        <Heading2>Edit and<br />re-export.</Heading2>
        <Description>Use built-in editor with advanced tools to quickly crop, cut, trim, re-arrange and re-export<br />recorded and imported videos.</Description>
        <SectionLink to={`${ERoute.Docs}/editing`}>Learn more about editing</SectionLink>
      </FeatureSection>
      <FeatureSection direction="reverse" imageSrc="/image/feature/capture.png">
        <Heading2>Capture only<br />what you need.</Heading2>
        <Description>Decide what to record, how to record it: cursor, mouse clicks and keyboard input, window shadow, desktop picture and more!</Description>
        <SectionLink to={`${ERoute.Docs}/recording#recording-settings`}>Learn more about capture settings</SectionLink>
      </FeatureSection>
      <FeatureSection direction="forward" imageSrc="/image/feature/export.png">
        <Heading2>Find optimal compression.</Heading2>
        <Description>Use the most complete GIF compression configuration found on any Mac app to gain the full control over the output quality and file size!</Description>
        <SectionLink to={`${ERoute.Docs}/exporting#export-configuration`}>Learn more about compression</SectionLink>
      </FeatureSection>
      <SharingFeature>
        <PageContainer>
          <SharingFeatureContent>
            <SharingIntegrationList>
              <SharingIntegration>
                <SharingIntegrationIcon icon="/image/integration-logo/dropbox-logo.svg" />
                <p>Dropbox</p>
              </SharingIntegration>
              <SharingIntegration>
                <SharingIntegrationIcon icon="/image/integration-logo/google-drive-logo.svg" />
                <p>Google Drive</p>
              </SharingIntegration>
              <SharingIntegration>
                <SharingIntegrationIcon icon="/image/integration-logo/imgur-logo.svg" />
                <p>Imgur</p>
              </SharingIntegration>
            </SharingIntegrationList>
            <Block maxWidth="40.5%" minHeight="11.375rem">
              <Heading2>Share with a click.</Heading2>
              <Description>Integrate Gifox with Dropbox, Google Drive and Imgur to easily share your captured GIFs right in the app, or drag-and-drop your recordings into other apps!</Description>
              <SectionLink to={`${ERoute.Docs}/sharing`}>Learn more about sharing</SectionLink>
            </Block>
          </SharingFeatureContent>
          <GetStartedBlock>
            <GetStartedContent>
              <Heading2>Ready to get started?</Heading2>
              <Description color={Colors.base1} opacity={0.5}>
                Get Gifox on your Mac now!
              </Description>
              <Row gap={20}>
                <Button variant={EButtonVariant.GradientDark} onClick={navigate(ERoute.Download)}>
                  Download
                </Button>
                <Button variant={EButtonVariant.OutlineLight} onClick={navigate(ERoute.Buy)}>
                  Buy Now
                </Button>
              </Row>
            </GetStartedContent>
            <GetStartedLogo />
          </GetStartedBlock>
        </PageContainer>
      </SharingFeature>
    </>
  );
};

const HeadingTitle = styled.h1.attrs((props) => ({
  style: { ...props },
}))<CSSProperties>`
  font-size: 2.875rem;
  font-family: ${Fonts.Spartan};
  font-weight: bold;
  margin: 0 0 2.125rem;

  ${mediaPhone} {
    font-size: 2.25rem;
  }

  ${mediaMobile} {
    margin-top: -3rem;
  }
  
  ${mediaHeaderCollapse} {
    margin-top: 0;
  }
`;

const HeadingDescription = styled(Description)`
  position: relative;
  margin: ${Spacings.spacingVerticalMedium} 0 -2.25rem;
  line-height: 1.25rem;
  z-index: 2;

  ${ExternalLink} {
    font-size: 1rem;
    font-family: ${Fonts.OpenSans};
    font-weight: normal;
    color: ${Colors.base2};
    text-decoration: underline;
    
    &:hover {
      color: ${Colors.primary};
      text-decoration: none;
    }
  }
`;

const MainButtonsRow = styled(Row)`
  ${mediaPhone} {
    max-width: 17rem;
    margin: 0 auto;

    > * {
      margin-right: auto;
    }
  }
`;

const Hero = styled.div`
  position: absolute;
  width: 100%;
  height: 42.25rem;
  top: 402px;
  left: 0;
  overflow: hidden;
  background: image-set(url("/image/hero_midres.png") 1x, url("/image/hero_midres@2x.png") 2x, url("/image/hero_midres@3x.png") 3x) no-repeat center;

  ${mediaMobile} {
    top: 399px;
    background-image: image-set(url("/image/hero_lowres.png") 1x, url("/image/hero_lowres@2x.png") 2x, url("/image/hero_lowres@3x.png") 3x);
  }

  ${mediaDesktopUltraWide} {
    top: 403px;
    background-image: image-set(url("/image/hero.png") 1x, url("/image/hero@2x.png") 2x, url("/image/hero@3x.png") 3x);
  }
`;

const SectionLink = styled(Link)`
  font-family: ${Fonts.Spartan};
  font-weight: 600;
  text-decoration: none;
  padding: 3px 0;
  border-bottom: 2px solid;

  :hover {
    text-decoration: none;
    color: ${Colors.primaryHover};
  }

  :active {
    text-decoration: none;
    color: ${Colors.primaryActive};
  }
`;

const SharingFeature = styled.div`
  background-color: ${Colors.base3};
  color: ${Colors.base1};
  padding: 6.25rem 0 5.1rem;
  margin: 0 0 -5rem;
`;

const SharingFeatureContent = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 60rem) {
    > div {
      min-width: 100% !important;
      margin-bottom: 3rem;
    }
  }
`;

const SharingIntegrationList = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex-shrink: 0;
  max-width: 45%;
  min-width: 45%;
  align-items: flex-start;
  justify-content: space-between;
  
  ${mediaLaptopMedium} {
    max-width: 50%;
    min-width: 50%;
  }
`;

const SharingIntegrationIcon = styled.div<{ icon: string }>`
  background-color: ${Colors.base1};
  border-radius: 1.875rem;
  width: 8.5625rem;
  height: 8.5625rem;
  box-shadow: 0 0 1.25rem 0 rgb(33 36 38 / 15%);
  margin-bottom: 1.5rem;
  background-position: 50% 50%;
  background-size: 65%;
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  transition: 0.3s ease-in-out;

  @media screen and (max-width: 60rem) {
    width: 5.125rem;
    height: 5.125rem;
    margin: 0 auto;
  }
`;

const SharingIntegration = styled.div`
  text-align: center;

  p {
    font-family: ${Fonts.OpenSans};
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
  }

  &:hover {
    ${SharingIntegrationIcon} {
      transform: scale(0.95);
    }
  }

  @media screen and (max-width: 69.75rem) {
    max-width: 8.75rem;
    margin: 0 auto;
  }
`;

const GetStartedContent = styled.div`
  max-width: 25.75rem;
  margin: 5.5rem 5.3175rem;

  ${mediaMobile} {
    margin: 5.5rem auto;

    ${Row} {
      > * {
        margin: 0;
      }
    }
  }
`;

const GetStartedLogo = styled.div`
  position: absolute;
  right: 2.125rem;
  top: 4.8125rem;
  width: 34.375rem;
  height: 29.875rem;
  background: transparent url('/image/logo.svg') no-repeat;
  background-size: 100%;
  z-index: -1;
  transition: 0.375s ease-in-out;

  ${mediaMobile} {
    left: 50%;
    top: 56%;
    margin-left: -14.0625rem;
    width: 28.125rem;
    height: 24.4375rem;
  }
`;

const GetStartedBlock = styled.div`
  position: relative;
  max-width: 73.75rem;
  min-height: 20rem;
  margin: 4rem auto 0;
  background: linear-gradient(180deg, #376acc 0%, #0e3e86 100%);
  text-align: center;
  border-radius: 1.875rem;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    width: 96.25rem;
    height: 87.625rem;
    left: 50%;
    margin-left: -48.125rem;
    top: -67.5rem;
    background: linear-gradient(180deg, #efefef 0%, #fafafa 52.28%);
    opacity: 0.1;
    border-radius: 50%;
    z-index: -1;
    transition: 0.8s ease-in-out;
  }
  
  &:hover {
    &:before {
      transform: scale(1.125);
    }

    ${mediaDesktop} {
      ${GetStartedLogo} {
        top: 2.8125rem;
      }
    }
  }

  @media screen and (max-width: 30rem) {
    margin: 0 auto;
    min-height: 30rem;
  }
`;

const SloganBit = styled.span``;
