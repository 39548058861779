import { Colors, mediaDesktopUltraWide, mediaMobile } from '@Shared/Style';
import styled from 'styled-components';

export const Arch = styled.div<{ top: number | string }>`
  position: absolute;
  width: 100%;
  top: ${({ top }) => (typeof top === 'string') ? top : `${top}px`};
  left: 0;
  z-index: -1;
  overflow: hidden;

  ${mediaMobile} {
    margin-top: -0.625rem;
  }

  ${mediaDesktopUltraWide} {
    margin-top: 6.625rem;
  }

  &:before {
    position: relative;
    display: block;
    content: '';
    left: 50%;
    width: 215rem;
    margin-left: -107.5rem;
    height: 195.75rem;
    background: ${Colors.accentGradient};
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    z-index: -1;
    min-height: 100rem;

    ${mediaMobile} {
      width: 96.25rem;
      height: 87.625rem;
      margin-left: -48.125rem;
    }

    ${mediaDesktopUltraWide} {
      left: 0;
      width: 100%;
      margin-left: 0;
      height: 200.25rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &:after {
    position: relative;
    display: block;
    content: '';
    width: 100%;
    height: 100vh;
    background-color: #FAFAFA;
  }
`;
