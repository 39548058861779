import React, { DependencyList, useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const usePageNavigator = (offset = 0, parallax?: boolean, dependencies: DependencyList = []): {
  navigator: React.RefObject<HTMLDivElement>,
  sectionsContainer: React.RefObject<HTMLDivElement>,
  currentSection: string,
  updatePosition: () => void,
} => {
  const navigator = useRef<HTMLDivElement>(null);
  const sectionsContainer = useRef<HTMLDivElement>(null);
  const [currentSection, setCurrentSection] = useState('');
  const [sectionsIds, setSectionIds] = useState<string[]>([]);

  useEffect(() => {
    const links = navigator.current?.querySelectorAll('a') ? Array.prototype.slice.call(navigator.current?.querySelectorAll('a')) : [];
    const IDs = links.map((link) => {
      const url = (link as HTMLAnchorElement).getAttribute('href') || '';
      return url.substring(url.indexOf('#') + 1);
    }).reverse() || [];
    if (JSON.stringify(sectionsIds) != JSON.stringify(IDs)) {
      setSectionIds(IDs);
    }
  }, [navigator, sectionsIds, dependencies]);

  const eventsToBind: [Document | Window, string][] = useMemo(
    () => [
      [document, 'scroll'],
      [window, 'resize'],
      [window, 'orientationchange'],
    ],
    [],
  );

  const pageNavigatorInitialPosition = 36;

  const updatePosition = useCallback(() => {
    const navigatorElement = navigator.current;
    if (!parallax || !navigatorElement) { return; }
    const overflowing = window.innerHeight <= navigatorElement.clientHeight;
    if (overflowing) {
      const verticalScroll: number = window.scrollY;
      const bottomLimit = pageNavigatorInitialPosition + navigatorElement.getBoundingClientRect().height - window.innerHeight;
      if (bottomLimit >= verticalScroll) {
        navigatorElement.style.top = `${-verticalScroll}px`;
      } else {
        navigatorElement.style.top = `${-bottomLimit}px`;
      }
    } else if (navigator.current) {
      navigator.current.style.top = '';
    }
  }, [parallax, pageNavigatorInitialPosition]);

  const observeWindowChange = useCallback(() => {
    updatePosition();
    if (!sectionsIds.some((sectionId) => {
      try {
        const verticalScroll: number = window.scrollY;
        const sectionElement = sectionsContainer.current?.querySelector(`#${sectionId}`) as HTMLElement;
        const sectionElementOffset = sectionElement?.getBoundingClientRect().top + verticalScroll || 0;
        if (sectionElement && verticalScroll >= sectionElementOffset - offset) {
          navigator.current?.querySelectorAll('a').forEach((link) => link.classList.remove('active'));
          navigator.current?.querySelector(`a[href*="${sectionId}"]`)?.classList.add('active');
          setCurrentSection(sectionId);
          return true;
        }
      } catch (e) {
        console.warn(e);
        return false;
      }

      return false;
    })) {
      setCurrentSection(sectionsIds[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, dependencies, navigator, sectionsIds]);

  useEffect(() => {
    observeWindowChange();

    eventsToBind.forEach((eventPair) => {
      eventPair[0].addEventListener(eventPair[1], observeWindowChange);
    });

    return () => {
      eventsToBind.forEach((eventPair) => {
        eventPair[0].removeEventListener(eventPair[1], observeWindowChange);
      });
    };
  }, [observeWindowChange, eventsToBind]);

  return {
    navigator,
    sectionsContainer,
    currentSection,
    updatePosition,
  };
};
