import { NetworkError, NetworkErrorType } from '@API/Checkout';
import { Currency } from '@Constant/Currency';
import { Block } from '@Shared/Layout/Block';
import { Colors, Fonts, Heading2, mediaMobile } from '@Shared/Style';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as exclamationIcon } from '@Static/image/icon/exclamation.svg';
import { ReactComponent as offlineIcon } from '@Static/image/icon/offline.svg';
import { Button, EButtonVariant } from './Button';
import { CountInput } from './CountInput';
import { PricingOptionBasicStyles, PricingOptionProps } from './PricingOption';

type PricingOptionFeaturedProps = PricingOptionProps & {
  totalPrice: string;
  currency: Currency | undefined;
  count: number;
  onCountChange: (count: number) => void;
  error?: NetworkError;
  isLoading?: boolean;
};

export const PricingOptionFeatured: React.FC<React.PropsWithChildren<PricingOptionFeaturedProps>> = ({ title, price, totalPrice, currency, count, onCountChange, buttonTitle, buttonDisabled, onClick, children, error, isLoading }) => (
  <PricingOptionCard>
    <Block>
      <PricingOptionTitle>{title}</PricingOptionTitle>
      <PricingOptionValue>
        <Price price={totalPrice} currency={currency} isLoading={isLoading} />
        {totalPrice != price ? (
          <OldPrice>
            <Price price={price} currency={currency} isLoading={isLoading} />
          </OldPrice>
        ) : null}
      </PricingOptionValue>
      <PricingOptionContent>{children}</PricingOptionContent>
    </Block>
    <Block>
      <PricingOptionCount>
        <CountInput value={count} onChange={onCountChange} min={1} max={999} declinations={["user", "users", "users"]} />
        <Button variant={EButtonVariant.Light} onClick={onClick} disabled={buttonDisabled}>
          {buttonTitle}
        </Button>
      </PricingOptionCount>
      <ErrorCallout error={error} />
    </Block>
  </PricingOptionCard>
);

const Price: React.FC<{ price: string, currency: Currency | undefined, isLoading?: boolean }> = ({ price, currency, isLoading }) => (
  <PriceContainer isLoading={isLoading}>
    {currency?.prefix ? (
      <PricingOptionPrefix>{currency.prefix}{' '}</PricingOptionPrefix>
    ) : null}

    {price}

    {currency?.suffix ? (
      <PricingOptionSuffix isSymbol={currency.suffix.length === 1}>{' '}{currency.suffix}</PricingOptionSuffix>
    ) : null}
    {isLoading ? <PriceSkeleton /> : null}
  </PriceContainer>
);

const ErrorCallout: React.FC<{ error?: NetworkError }> = ({ error }) => {
  if (!error) { return null; }
  const Icon = error.type == NetworkErrorType.Network ? offlineIcon : exclamationIcon;
  return (
    <PricingOptionError>
      <>
        <Icon />
        {error.message}
      </>
    </PricingOptionError>
  );
};

const PricingOptionCard = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 26.25rem;
  min-height: 35.625rem;
  background: linear-gradient(333.52deg, #FE5252 18.38%, #FF8A4E 85.53%);
  box-shadow: 0px 12px 24px rgba(151, 151, 151, 0.25);
  z-index: 2;

  ${PricingOptionBasicStyles};
  padding: 6.5rem 2.5rem 112px;
  color: ${Colors.base1};
`;

const PricingOptionTitle = styled.div`
  font-family: ${Fonts.Spartan};
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1.825rem;
`;

const PriceContainer = styled.span<{ isLoading: boolean }>`
  position: relative;
  ${({ isLoading }) => isLoading ? 'color: transparent' : ''};
  min-width: 9.625rem;
  min-height: 2.09375rem;
`;

export const skeletonAnimation = keyframes`
  0% {
    background-position-x: 0;
  }
  50% {
    background-position-x: -200%;
  }
  100% {
    background-position-x: -400%;
  }
`;

const PriceSkeleton = styled.div`
  position: absolute;
  top: -4px;
  left: 0;
  bottom: 2px;
  display: block;
  width: 100%;
  background: linear-gradient(90deg, rgba(217, 217, 217, 0.2) 0%, rgba(239, 239, 239, 0.4) 50%, rgba(217, 217, 217, 0.2) 100%);
  background-size: 400%;
  animation: ${skeletonAnimation} 4s linear infinite;
  border-radius: 8px;
`;

const PricingOptionValue = styled(Heading2)`
  display: flex;
  margin-bottom: 0;
  margin-right: -1rem;
  flex-flow: row wrap;
  align-items: flex-start;
  gap: 0.625rem;
  justify-content: flex-start;
  color: ${Colors.base5}
  font-size: 2.875rem;
`;

const PricingOptionPrefix = styled.span`
`;

const PricingOptionSuffix = styled.span<{ isSymbol?: boolean }>`
`;

const PricingOptionContent = styled.div`
  margin: 1.875rem 0 0;
`;

const PricingOptionCount = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  gap: 0.625rem;

  > button {
    width: 100%;
    margin: 0;
  }

  ${mediaMobile} {
    flex-wrap: wrap;
  }
`;

const PricingOptionError = styled.div`
  position: absolute;
  bottom: 2.125rem;
  left: 2.625rem;
  right: 2.625rem;
  padding: 0.975rem 1.25rem 0.975rem 3.125rem;
  background-color: ${Colors.base1};
  color: ${Colors.primary};
  border-radius: 8px;
  font-size: 0.875rem;
  color: ${Colors.error};
  
  svg {
    position: absolute;
    left: 1.25rem;
    top: 0.975rem;
  }
  
  ${mediaMobile} {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 1.725rem;
  }
`;

const OldPrice = styled.span`
  display: inline-flex;

  ${PriceContainer} {
    font-size: 1rem;
    text-decoration: line-through;
    font-weight: normal;
  }
`;
