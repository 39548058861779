import { useQuery } from 'react-query';
import { Currency, currencyDictionary } from '@Constant';
import { getPaddlePrice, GetPaddlePriceParams } from './Remote';

export const usePaddlePriceQuery = (params: GetPaddlePriceParams) => useQuery(['paddle_prices', params.productId], () => getPaddlePrice(params), {
  keepPreviousData: true,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
});

export const usePaddlePrice = (params: GetPaddlePriceParams & { quantity: number }): [number | undefined, number | undefined, Currency | undefined, boolean] => {
  const query = usePaddlePriceQuery(params);

  const product = query.data?.data.product;
  const discount = query.data?.data.discount;
  const basePrice = product?.price.gross;
  let price = basePrice;
  const currency = (product?.currency && (currencyDictionary[product.currency] ?? { suffix: product.currency })) || undefined;

  if (discount && price) { price = calculateDiscountPrice(discount, params.quantity, price); }

  return [price, basePrice, currency, query.isLoading];
};

export const calculateDiscountPrice = (discountMap: Record<number, number>, quantity: number, price: number): number => {
  const discountQuantities = Object.keys(discountMap).map((q) => Number(q));
  const discount = discountQuantities.reduce((f, d) => {
    if (quantity >= d) { return discountMap[d]; }
    return f;
  }, 0);

  return Number((price - price * discount).toFixed(2));
};
