export * from './ChildTextSearch';
export * from './PaddleHelper';
export * from './Analytics';

export const constructOpenLink = (url?: string, target?: string, features?: string, replace?: boolean) => () => window.open(url, target, features, replace);

export const constructSendMail = (address: string, subject?: string) => () => window.open(`mailto:${address}?subject=${subject}`);

export const declination = (number: number, titles: [string, string, string]): string => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
};

export const outerSize = (element: HTMLElement): { width: number, height: number } => {
  const style = window.getComputedStyle(element);
  return {
    width: parseFloat(style.width) + parseFloat(style.marginLeft) + parseFloat(style.marginRight),
    height: parseFloat(style.height) + parseFloat(style.marginTop) + parseFloat(style.marginBottom),
  };
};
