import React, { ImgHTMLAttributes } from 'react';

type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  hasDensityVariants?: boolean;
};

export const Image: React.FC<ImageProps> = ({ hasDensityVariants, src, ...props }) => {
  const imgProps = {
    ...(hasDensityVariants && src ? {
      srcSet: `${src.replace(/(\.[^.]+)$/, '@2x$1')} 2x, ${src.replace(/(\.[^.]+)$/, '@3x$1')} 3x`,
    } : {}),
  };
  return <img draggable="false" src={src} {...imgProps} {...props} />;
};
