export const Colors = {
  base1: '#FFFFFF',
  base1Dark: '#E9E9E9',
  base2: '#BABAC2',
  base2Lighten: '#DDDDE0',
  base3: '#303035',
  base3Lighten: '#6E6E72',
  base4: '#888888',
  base5: '#595959',
  primary: '#FF6435',
  primaryHover: '#FF271A',
  primaryActive: '#D41F14',
  primaryGradient: 'linear-gradient(180deg, #3273F0 0%, #104FAD 100%)',
  primaryTransparent: 'rgba(255, 53, 53, 0.1)',
  accentLight: '#FAFAFA',
  accentGradient: 'linear-gradient(180.74deg, #EFEFEF 0.64%, #FAFAFA 45.68%)',
  buttonGradient: 'linear-gradient(344.75deg, #FE5252 10.76%, #FF8A4E 86.09%)',
  buttonGradientHover: 'linear-gradient(180deg, #FF6724 0%, #FF271A 100%)',
  buttonGradientActive: 'linear-gradient(primaryHover180deg, #FF6724 0%, #FF271A 100%)',
  footer: '#262626',
  border: '#EFEFEF',
  transparent: 'transparent',
  error: '#FF0000',
};

export const Spacings = {
  spacingVerticalLarge: '3.125rem',
  spacingVerticalMedium: '1.5625rem',
  spacingVerticalText: '1.25rem',
  spacingHorizontalMedium: '1.75rem',
};

export const Sizes = {
  containerWidth: '73.75rem',
};

export const Roundings = {
  buttonRounding: '0.375rem',
};

export const Fonts = {
  Spartan: `'Spartan', -apple-system, sans-serif`,
  OpenSans: `'Open Sans', -apple-system, sans-serif`,
  GolosTextVF: `'Golos Text VF', -apple-system, sans-serif`,
};
