import { ERoute } from '@App/Router';
import { MarkdownContent, MobileNavigator, PageNavigator } from '@Shared/Component';
import { useScrollToElement } from '@Shared/Hook/ScrollToElement';
import React, { useCallback, useEffect, useState } from 'react';
import { Block, DocumentationBlock, PageContainer } from '@Shared/Layout';
import { NavigatorScrollHashLink } from '@Shared/Style';
import { usePageNavigator } from '@Shared/Hook/PageNavigator';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import privacyContentURL from '@Docs/Legal/Privacy.md';

export const PrivacyPage: React.FC = () => {
  const { navigator } = usePageNavigator(400);
  const location = useLocation();
  const { scrollToElement, isScrolling } = useScrollToElement();
  const [currentKey, setCurrentKey] = useState<string>("");
  const [content, setContent] = useState<string>('');
  const [chapters, setChapters] = useState<Record<string, { name: string }>>({});

  useEffect(() => {
    const newKey = location.hash.substring(1);
    if (newKey && newKey != currentKey && !isScrolling) {
      setCurrentKey(newKey);
      scrollToElement(`#${newKey}`);
    }
  }, [location.hash, currentKey, scrollToElement, isScrolling]);

  useEffect(() => {
    fetch(privacyContentURL)
      .then((res) => res.text())
      .then((markdown) => {
        setContent(markdown);
        const markdownChapters = {} as Record<string, { name: string }>;
        Array.from(markdown.matchAll(/\n##\s+([\w\s]+)/g)).forEach((match) => {
          const key = match[1].toLowerCase().replaceAll?.(' ', '-') ?? '';
          markdownChapters[key] = { name: match[1] };
        });
        setChapters(markdownChapters);
        setCurrentKey(Object.keys(markdownChapters)[0]);
      });
  }, []);

  const buildNavigatorLink = useCallback((key: string) => `${ERoute.Privacy}#${key}`, []);

  const title = 'Privacy policy and data processing – Gifox Legal 🦊';
  const description = 'Find out what data we collect, how and why we use it, how we ensure its safety, how long we keep it for, and how you can access and manage it.';

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:locale" content="en" />
        <meta property="og:url" content="https://gifox.app/privacy" />
        <meta property="og:image" content="https://gifox.app/image/gifox.gif" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={description} />
        <meta name="description" content={description} />
      </Helmet>
      <MobileNavigator entries={chapters} activeKey={currentKey} linkBuilder={buildNavigatorLink} />
      <PageContainer>
        <PageColumns>
          <PageNavigator ref={navigator} className="mobile-hide">
            {Object.entries(chapters)
              .map(([key, entry]) => (
                <NavigatorScrollHashLink key={key} to={buildNavigatorLink(key)}>
                  {entry.name}
                </NavigatorScrollHashLink>
              ))}
          </PageNavigator>
          <Block minHeight="70vh" maxWidth="52rem">
            <DocumentationBlock>
              <MarkdownContent>
                {content || ''}
              </MarkdownContent>
            </DocumentationBlock>
          </Block>
        </PageColumns>
      </PageContainer>
    </>
  );
};

const PageColumns = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 60rem) {
    flex-flow: row wrap;

    ${PageNavigator} {
      position: relative;
      top: 0;
      margin-right: 0;
    }
  }
`;
