import { withScrollHandled } from '@Shared/Component/HOC';
import { mediaMobile } from '@Shared/Style/Global';
import React from 'react';
import styled, { css, CSSProperties } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { genericHashLink, HashLinkProps } from 'react-router-hash-link';
import { Colors, Fonts, Spacings } from './Variables';

export const Anchor = styled.a`
  position: relative;
  display: inline-block;
  opacity: 0;
  margin-left: -2rem;
  width: 2rem;
  height: 2rem;
  line-height: 2.125rem;
  text-decoration: none;
  vertical-align: text-top;

  ${mediaMobile} {
    margin-left: -20px;
    width: 20px;
    height: 20px;
    font-size: 0.75rem;
    line-height: 20px;
    vertical-align: middle;
  }
  
  &.active {
    opacity: 1;
  }
`;

const basicHeadingStyles = css`
  :hover {
    ${Anchor} { opacity: 1 }
  }
`;

export const Heading1 = styled.h1.attrs((props) => ({
  style: { ...props },
}))<CSSProperties>`
  ${basicHeadingStyles};
  font-size: 2.1875rem;
  font-family: ${Fonts.Spartan};
  font-weight: bold;
  margin: 0 0 2.25rem;
`;

export const Heading2 = styled.h2.attrs((props) => ({
  style: { ...props },
}))<CSSProperties>`
  ${basicHeadingStyles};
  font-size: 1.75rem;
  font-family: ${Fonts.Spartan};
  font-weight: bold;
  margin: 0 0 0.9375rem;
`;

export const Heading3 = styled.h3.attrs((props) => ({
  style: { ...props },
}))<CSSProperties>`
  ${basicHeadingStyles};
  font-size: 1.25rem;
  font-family: ${Fonts.Spartan};
  font-weight: bold;
  margin: 0 0 0.9375rem;

  ${Anchor} {
    vertical-align: bottom;

    ${mediaMobile} {
      vertical-align: middle;
    }
  }
`;

export const Heading4 = styled.h4.attrs((props) => ({
  style: { ...props },
}))<CSSProperties>`
  ${basicHeadingStyles};
  font-size: 1.125rem;
  font-family: ${Fonts.Spartan};
  font-weight: bold;
  margin: 0 0 0.9375rem;
`;

export const Text = styled.p.attrs((props) => ({
  style: { ...props },
}))<CSSProperties>`
  font-size: 1rem;
  font-family: ${Fonts.OpenSans};
  font-weight: normal;
  margin: 0;
`;

export const TextBold = styled(Text).attrs((props) => ({
  style: { ...props },
}))<CSSProperties>`
  font-weight: bold;
  margin: 0;
`;

export const cssLinkStyles = css`
  color: ${Colors.primary};
  text-decoration: none;
  outline: 0;

  &:hover {
    color: ${Colors.primaryHover};
    text-decoration: underline;
  }
`;

export const LinkHighlighted = styled(RouterLink)`
  ${cssLinkStyles};
`;

export const cssSilentLinkStyles = css`
  color: ${Colors.base3};
  
  &:hover {
    color: ${Colors.primary};
    text-decoration: underline;
  }
`;

export const GenericLink = styled(RouterLink).attrs((props) => ({
  style: { ...props },
}))<CSSProperties>`
  ${cssSilentLinkStyles};
`;

export const GenericHashLink = genericHashLink(GenericLink) as React.FC<HashLinkProps>;

export const Link = styled(RouterLink).attrs((props) => ({
  style: { ...props },
}))<CSSProperties & { $active?: boolean }>`
  ${cssLinkStyles};
  font-family: ${Fonts.Spartan};
  font-weight: bold;

  ${({ $active }) => $active && css`
    color: ${Colors.primary} !important;
  `}
`;

export const ExternalLink = styled.a.attrs((props) => ({
  target: props.target || '_blank',
  rel: props.rel || 'noreferrer',
}))`
  ${cssLinkStyles};
`;

export const ExternalSilentLink = styled.a.attrs((props) => ({
  target: props.target || '_blank',
  rel: props.rel || 'noreferrer',
}))`
  ${cssSilentLinkStyles};
`;

export const Description = styled(Text).attrs((props) => ({
  style: { ...props },
}))<CSSProperties>`
  font-size: 1rem;
  margin: 0 0 ${Spacings.spacingVerticalText};
  line-height: 1.5rem;
`;

export const NavigatorLink = styled(Link)<{ active?: boolean }>`
  font-size: 1rem;
  font-family: ${Fonts.Spartan};
  font-weight: bold;
  color: ${({ active }) => (active ? Colors.primary : Colors.base3)};
  text-decoration: none;
  border-bottom: 0;

  &:hover {
    text-decoration: none;
    color: ${Colors.primary};
  }

  &.active {
    color: ${Colors.primary};
  }
`;

export const NavigatorHashLink: React.FC<HashLinkProps> = genericHashLink(NavigatorLink) as React.FC<HashLinkProps>;
export const NavigatorScrollHashLink = withScrollHandled(NavigatorLink);
export const KeyShortcut = styled.span`
  display: inline-block;
  margin: 3px 5px;
  background-color: ${Colors.base1};
  color: ${Colors.primary};
  box-shadow: 0px 1px 6px rgba(61, 32, 17, 0.25);
  padding: 0 10px;
  border-radius: 10px;
  cursor: default;
  line-height: 28px;
`;

export const Quote = styled.div`
  display: block;
  border: 1px solid ${Colors.base2Lighten};
  background-color: ${Colors.base1};
  padding: 2.1875rem 1.5625rem 2.5rem;
  margin: 1.25rem 0 1.875rem;
  font-size: 1rem;
  border-radius: 15px;

  ul {
    margin-top: 1.875rem;
  }
`;

export const Table = styled.table`
  display: table;
  width: 100%;
  margin-bottom: 0.875rem;
  border-spacing: 0;

  th, td {
    padding: 0.375rem 0.875rem;
    border-bottom: 1px solid ${Colors.border};
    &:first-of-type {
      text-align: left;
    }

    ${KeyShortcut}:last-of-type {
      margin-right: 0;
    } 
  }
  
  tr:hover {
    th, td {
      background-color: ${Colors.accentLight};
    }
  }
`;
