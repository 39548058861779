import { useQuery } from 'react-query';
import { postLocate } from './Remote';

const useGeoLocationQuery = () => useQuery('locate', postLocate, {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
});

type Location = {
  city: string;
  country: string;
};

export const useGeoLocation = (): Location => {
  const query = useGeoLocationQuery();

  return {
    city: query.data?.data.city || '',
    country: query.data?.data.country || '',
  };
};
