import { useGeoLocation } from '@API/GeoLocation';
import { ERoute } from '@App/Router';
import { MAIL_SUPPORT, URL_GITHUB_TRACKER, URL_PRODUCTHUNT, URL_TWITTER } from '@Constant';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { EFooterTheme, useThemeContext } from '../Context';
import { Colors, ExternalLink, Fonts, Link, mediaGeneralAdaptive } from '../Style';
import { PageContainer, Row } from './Block';

export const Footer: React.FC = () => {
  const { theme } = useThemeContext();
  const { city } = useGeoLocation();

  const year = new Date().getFullYear().toString(10);

  return (
    <FooterBlock theme={theme.footerTheme}>
      <PageContainer>
        <Row justifyContent="space-between" alignItems="flex-start">
          <FooterColumns>
            <FooterColumn>
              <FooterColumnHeader>Product</FooterColumnHeader>
              <SiteLink to={ERoute.Download}>Download</SiteLink>
              <SiteLink to={ERoute.Changelog}>Changelog</SiteLink>
              <SiteLink to={ERoute.Buy}>Pricing</SiteLink>
            </FooterColumn>
            <FooterColumn>
              <FooterColumnHeader>Support</FooterColumnHeader>
              <SiteLink to={ERoute.Docs}>Docs</SiteLink>
              <SiteLink to={ERoute.FAQ}>FAQ</SiteLink>
              <OuterLink href={`mailto:${MAIL_SUPPORT}?subject=Hello`} target="_self">Contact Us</OuterLink>
            </FooterColumn>
            <FooterColumn>
              <FooterColumnHeader>Follow us</FooterColumnHeader>
              <OuterLink href={URL_PRODUCTHUNT}>ProductHunt</OuterLink>
              <OuterLink href={URL_TWITTER}>Twitter</OuterLink>
              <OuterLink href={URL_GITHUB_TRACKER}>GitHub</OuterLink>
            </FooterColumn>
            <FooterColumn>
              <FooterColumnHeader>Legal</FooterColumnHeader>
              {/* <SiteLink to="/">EULA</SiteLink> */}
              <SiteLink to={ERoute.Privacy}>Privacy Policy</SiteLink>
              <SiteLink to={ERoute.Terms}>Terms of Use</SiteLink>
              {/* <SiteLink to="/">Press Kit</SiteLink> */}
            </FooterColumn>
          </FooterColumns>
          <FooterCopyright>
            © {year} Interactive Works LTD
            <br />
            {city ? 'We' : 'Made with'} <HeartIcon src="/image/icon/heart.svg" alt="❤️" /> {city || 'in London'}
          </FooterCopyright>
        </Row>
      </PageContainer>
    </FooterBlock>
  );
};

const SiteLink: React.FC<{ to: ERoute }> = ({ to, children }) => {
  const location = useLocation();
  const { theme } = useThemeContext();

  return <FooterLink theme={theme.footerTheme} $active={location.pathname.startsWith(to)} to={to}>{children}</FooterLink>;
};

const OuterLink: React.FC<{ href: string, target?: string }> = ({ href, target, children }) => {
  const { theme } = useThemeContext();

  return <FooterExternalLink theme={theme.footerTheme} href={href} target={target}>{children}</FooterExternalLink>;
};

const getFooterColor = (theme: EFooterTheme): string => {
  switch (theme) {
    case EFooterTheme.Light:
      return Colors.base1;
    case EFooterTheme.Accent:
      return Colors.accentLight;
    case EFooterTheme.Transparent:
      return Colors.transparent;
    default:
      return Colors.footer;
  }
};

const FooterBlock = styled.div<{ theme: EFooterTheme }>`
  background-color: ${({ theme }) => getFooterColor(theme)};
  color: ${Colors.base4};
  padding: 3.1875rem 0;
  margin-top: 5rem;
`;

const FooterColumns = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 45%;

  ${mediaGeneralAdaptive} {
    width: 100%;
    margin-bottom: 1.5rem;
  }
`;

const FooterColumn = styled.div`
  display: flex;
  flex-flow: column;
`;

const FooterColumnHeader = styled.div`
  text-transform: uppercase;
  margin-bottom: 1.4375rem;
  font-family: ${Fonts.OpenSans};
  font-size: 1rem;
  font-weight: bold;
`;

const cssFooterLinkStyles = css`
  color: ${Colors.base4};
  text-decoration: none;
  font-family: ${Fonts.OpenSans};
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 0.6875rem;
  border-bottom: 0;

  &:hover {
    border-bottom: 0;
    text-decoration: none;
  }
`;

const FooterLink = styled(Link)<{ $active?: boolean, theme: EFooterTheme }>`
  ${cssFooterLinkStyles};
  font-weight: ${({ $active }) => $active ? '600' : 'normal'};
  
  &:hover {
    color: ${({ theme }) => theme === EFooterTheme.Dark ? Colors.base1 : Colors.primaryHover}
  }
  ${({ $active, theme }) => $active ? `
    color: ${theme === EFooterTheme.Dark ? Colors.base1 : Colors.base4}
  ` : ''}
`;

const FooterExternalLink = styled(ExternalLink)<{ theme: EFooterTheme }>`
  ${cssFooterLinkStyles};

  &:hover {
    color: ${({ theme }) => theme === EFooterTheme.Dark ? Colors.base1 : Colors.primaryHover}
  }
`;

const FooterCopyright = styled.div`
  font-family: ${Fonts.OpenSans};
  font-size: 0.875rem;
  text-align: right;
  line-height: 1.6rem;

  ${mediaGeneralAdaptive} {
    text-align: center;
    width: 100%;
  }
`;

const HeartIcon = styled.img`
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  vertical-align: middle;
`;
