import { usePrevious } from '@Shared/Hook/Previous';
import { Colors, Fonts } from '@Shared/Style';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export const Tip: React.FC<React.PropsWithChildren & { open: boolean }> = ({ children, open }) => {
  const [tipState, setTipState] = useState<TipState>(TipState.hidden);
  const previousOpen = usePrevious<boolean>(open);

  useEffect(() => {
    if (previousOpen != open) {
      if (open) {
        setTipState(TipState.open);
      } else {
        setTipState(TipState.hiding);
        setTimeout(() => {
          setTipState(TipState.hidden);
        }, 200);
      }
    }
  }, [open, previousOpen]);

  return open || (tipState != TipState.hidden) ? <TipSpan className={tipState}>{children}</TipSpan> : null;
};

enum TipState {
  hidden = 'hidden',
  open = 'open',
  hiding = 'hiding',
}

const TipSpan = styled.span`
  position: absolute;
  display: block;
  top: 0;
  right: 100%;
  margin-right: -14px;
  padding:  0.1875rem 0.5rem 0.25rem;
  background: #2ECC51;
  color: ${Colors.base1};
  box-shadow: 0px 1px 6.8px rgba(61, 32, 17, 0.25);
  border-radius: 10px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: ${Fonts.OpenSans};
  font-weight: normal;
  opacity: 0;
  transition: 0.2s ease-in-out;

  :before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5.5px;
    background: url('data:image/svg+xml;utf8,<svg width="11" height="4" viewBox="0 0 11 4" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 4C6.6 4 8.06667 6.97234e-07 11 9.53674e-07L-6.03983e-07 -7.97631e-09C2.93333 2.48464e-07 4.4 4 5.5 4Z" fill="%232ECC51"/></svg>');
    height: 4px;
    width: 11px;
  }

  &.${TipState.open} {
    opacity: 1;
    top: -100%;
  }

  &.${TipState.hidden} {
    opacity: 0;
    top: 0;
  }
`;
