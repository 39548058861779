import styled, { CSSProperties } from 'styled-components';
import { Description, Heading1, Heading2, Heading3, Heading4, KeyShortcut, mediaHeaderCollapse, Sizes, Spacings } from '../Style';

export const LayoutRoot = styled.div.attrs((props) => ({
  style: { ...props },
}))<CSSProperties>`
  display: flex;
  flex-flow: column;
  min-height: 100%;
  overflow: hidden;

  ${mediaHeaderCollapse} {
    padding-top: 9.125rem;
  }
`;

export const ContentRoot = styled.div`
  flex: 1;
`;

export const PageContainer = styled.div.attrs((props) => ({
  style: { ...props },
}))<CSSProperties>`
  max-width: ${Sizes.containerWidth};
  margin: 0 auto;
  padding: 0 ${Spacings.spacingHorizontalMedium};

  img {
    max-width: 100%;
  }
`;

type RowProps = CSSProperties & {
  childSpacing?: number | string;
};

export const Row = styled.div.attrs((props) => ({
  style: { ...props },
}))<RowProps>`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  > * {
    margin-right: ${({ childSpacing }) => typeof childSpacing === 'number' ? `${childSpacing}px` : childSpacing};
  }

  > :last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 960px) {
    > :last-child {
      margin-right: ${({ childSpacing }) => childSpacing || 0}px;
    }
  }

  @media screen and (max-width: 480px) {
    > * {
      margin: 0 auto;
    }

    > :last-child {
      margin-right: auto;
    }
  }
`;

export const Block = styled.div.attrs((props) => ({
  style: { ...props },
}))<RowProps>``;

export const DocumentationBlock = styled(Block)`
  ${Heading1}, ${Heading2}, ${Heading3}, ${Heading4} {
    margin: 2.8125rem 0 0.625rem;

    &:first-child {
      margin-top: 0;
    }
  }

  ${Description} {
    margin: 0;
    
    + ul, + li {
      margin-top: 1.875rem;
    }
  }

  img {
    display: block;
    margin: 1.875rem auto 2.1875rem;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  li {
    margin: 0 0 0.625rem;

    ${KeyShortcut} {
      margin: -0.3125rem 5px;
    }
  }
  
  p + ul,
  p + ol {
    margin-top: 0.625rem;
  }
`;

export const Spacing = styled.div.attrs((props) => ({
  style: { ...props },
}))<CSSProperties>``;
