import axios, { AxiosResponse } from 'axios';
import { API_BASE } from '@Constant';

/**
 * LOCATION
 */
type LocateResponse = {
  city: string;
  country: string;
  success: boolean;
};

export const postLocate = (): Promise<AxiosResponse<LocateResponse>> => axios.post(`${API_BASE}/locate`);

/**
 * CHECKOUT
 */
export type PostPaddleCheckoutParams = {
  productId: number;
  quantity: number;
};

export type PostPaddleCheckoutResponse = {
  success: boolean;
  payLink: string;
};

export const postPaddleCheckout = (params: PostPaddleCheckoutParams): Promise<AxiosResponse<PostPaddleCheckoutResponse>> => axios.post(`${API_BASE}/checkout`, params);

/**
 * PRICE
 */
type PaddlePrice = {
  gross: number;
  net: number;
  tax: number;
  tax_included: boolean;
};

type PaddlePriceProduct = {
  applied_coupon: string[];
  currency: string;
  list_price: PaddlePrice;
  price: PaddlePrice;
  product_id: number;
  product_title: string;
  vendor_set_prices_included_tax: boolean;
};

type DiscountRecord = {
  [key: number]: number;
};

type PaddlePriceResponse = {
  success: boolean;
  product: PaddlePriceProduct | null;
  discount: DiscountRecord;
};

export type GetPaddlePriceParams = {
  productId?: number;
};

export const getPaddlePrice = (params: GetPaddlePriceParams): Promise<AxiosResponse<PaddlePriceResponse>> => axios.get(`${API_BASE}/price${params.productId ? `?productId=${params.productId}` : ''}`);
