import { ERoute } from '@App/Router';
import { DocumentationPage } from '@Page/Documentation/DocumentationPage';
import { NotFoundPage } from '@Page/NotFoundPage';
import { useDocumentationContext } from '@Shared/Context';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, RouteComponentProps, useParams } from 'react-router-dom';

export const DocumentationRouter: React.FC<RouteComponentProps> = () => {
  const params = useParams<{ key: string }>();
  const { documentation, setKey } = useDocumentationContext();
  const documentExists = Object.keys(documentation).includes(params.key);

  useEffect(() => {
    setKey(params.key);
  }, [params, setKey]);

  return (
    <>
      <Helmet>
        <title>Create high-quality animated GIF – Gifox Docs 🦊</title>
      </Helmet>
      {params.key ? (
        <>
          {documentExists ? (
            <Route path={`${ERoute.Docs}/:key`} component={DocumentationPage} />
          ) : (
            <Route path={`${ERoute.Docs}/:key`} component={NotFoundPage} />
          )}
        </>
      ) : (
        <Redirect to={`${ERoute.Docs}/${Object.keys(documentation)[0]}`} />
      )}
    </>
  );
};
