import { ERoute } from '@App/Router';
import { MobileNavigator, PageNavigator, MarkdownContent } from '@Shared/Component';
import { useDocumentationContext } from '@Shared/Context';
import { useScrollToElement } from '@Shared/Hook/ScrollToElement';
import React, { useCallback, useEffect } from 'react';
import { Block, DocumentationBlock, PageContainer } from '@Shared/Layout';
import { NavigatorLink } from '@Shared/Style';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { RouteComponentProps, useParams, useLocation } from 'react-router-dom';

export const DocumentationPage: React.FC<RouteComponentProps> = () => {
  const { documentation } = useDocumentationContext();
  const location = useLocation();
  const params = useParams<{ key: string }>();
  const { scrollToElement } = useScrollToElement();
  const value = documentation[params.key] || {};

  useEffect(() => {
    if (!value.isLoading && location.hash) {
      scrollToElement(location.hash);
    }
  }, [location.hash, value.isLoading, scrollToElement]);

  const buildNavigatorLink = useCallback((key: string) => `${ERoute.Docs}/${key}`, []);

  const title = value.title || 'Gifox Docs 🦊';
  const description = value.description || 'Gifox documentation.';

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:locale" content="en" />
        <meta property="og:url" content={`https://gifox.app/${params.key}`} />
        <meta property="og:image" content="https://gifox.app/image/gifox.gif" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={description} />
        <meta name="description" content={description} />
      </Helmet>
      <MobileNavigator entries={documentation} activeKey={params.key} linkBuilder={buildNavigatorLink} />
      <PageContainer>
        <PageColumns>
          <PageNavigator className="mobile-hide">
            {Object.entries(documentation)
              .map(([key, entry]) => (
                <NavigatorLink key={key} to={buildNavigatorLink(key)} $active={params.key === key}>
                  {entry.name}
                </NavigatorLink>
              ))}
          </PageNavigator>
          <Block minHeight="70vh" maxWidth="52rem">
            <DocumentationBlock>
              <MarkdownContent>
                {value?.content || ''}
              </MarkdownContent>
            </DocumentationBlock>
          </Block>
        </PageColumns>
      </PageContainer>
    </>
  );
};

const PageColumns = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 60rem) {
    flex-flow: row wrap;
  }
`;
