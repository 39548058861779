import { Tip } from '@Shared/Component/Tip';
import { Anchor } from '@Shared/Style';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Decorates given component with anchor link like GitHub does.
 * Be aware that component must have id attribute.
 * @param WrappedComponent
 */
export const withAnchor = <T extends React.PropsWithChildren & { id: string }>(WrappedComponent: React.JSXElementConstructor<T>) => ({ id, children, ...props }: T): JSX.Element => {
  const history = useHistory();
  const [showTip, setShowTip] = useState(false);

  const onClick = useCallback((e: React.SyntheticEvent<HTMLAnchorElement | undefined>) => {
    e.preventDefault();
    history.push(`#${id}`);
    setShowTip(true);
    navigator.clipboard.writeText(window.location.href);
    setTimeout(() => {
      setShowTip(false);
    }, 3200);
  }, [id, history]);

  return (
    <WrappedComponent {...({ id, ...props } as T)} style={{ position: 'relative' }}>
      <Tip open={showTip}>Copied</Tip>
      <Anchor href={`#${id}`} onClick={onClick} className={showTip ? "active" : ""}>🔗</Anchor>
      {children}
    </WrappedComponent> as JSX.Element
  );
};

export const withScrollHandled = <T extends React.PropsWithChildren & { to: string }>(WrappedComponent: React.JSXElementConstructor<T>) => ({ to, children, ...props }: T): JSX.Element => {
  const history = useHistory();

  const onClick = useCallback((e: React.SyntheticEvent<HTMLAnchorElement | undefined>) => {
    e.preventDefault();
    history.push(to);
  }, [to, history]);

  return (
    <WrappedComponent {...({ to, ...props } as T)} onClick={onClick}>
      {children}
    </WrappedComponent> as JSX.Element
  );
};
